<template>
	<div>
		<template v-if="searchResults.length === 0">
			No results found.
		</template>
		<template v-else>
			<b-table
				:fields="fields"
				:items="searchResults"
				show-empty
				stacked="md"
				striped
				hover
				small
				@sort-changed="handleSort"
				:no-local-sorting="true"
				:sort-by.sync="sort.column"
				:sort-desc.sync="sort.desc"
			>
				<template slot="cell(name)" slot-scope="row">
					<router-link :to="getParametersForProductDetails(row.item.id, selectedListId || baseListId)">
						{{ getName(row) }}
					</router-link>
				</template>
				<template slot="cell(distributor)" slot-scope="row">
					{{ extractField(extractDataFromList(row.item.data.indexData, 'distributor', false), 'label') }}
				</template>
				<template slot="cell(category)" slot-scope="row">
					{{ getMainCategoryId(row.item) | toCategoryDisplay }}
				</template>
				<template slot="cell(lists)" slot-scope="row">
					<ul v-if="baseListSelected && getListEntries(row.item).length > 0">
						<li v-for="(entry, index) in getListEntries(row.item)" :key="`${entry.key}-${index}`">
							<router-link :to="getParametersForProductDetails(row.item.id, entry.key)">
								{{ entry.key | toListDisplay }}
							</router-link>
						</li>
					</ul>
				</template>
			</b-table>

			<paginator
				pagination-prefix="search"
				@pagination-updated="onUpdatePagination"
			/>
		</template>
	</div>
</template>

<script>
import { SearchOptionsProvider } from '@/services/SearchOptionsProvider';
import { extractField, extractDataFromList } from '@/util/locale';
import Paginator from '@/components/SearchControls/General/Paginator';
import { fieldToLocale } from '@/util/locale';
import { getHistoricName } from '@/util/history';

export default {
	name: 'SearchResults',

	components: {
		Paginator
	},

	data() {
		return {
			fields: [],
			sort: { column: 'name', desc: false },
			queueStatus: { eta: 0, position: 0 },
			pdfWorking: false
		};
	},

	computed: {
		searchResults() {
			return this.$store.getters['search/searchResults'];
		},
		searchConfiguration() {
			return this.$store.getters['search/lastSearch'];
		},
		baseListId() {
			return this.$store.getters['list/baseListId'];
		},
		selectedListId() {
			const searchConfiguration = this.searchConfiguration;
			if (!searchConfiguration) {
				return null;
			}
			if (!searchConfiguration.list) {
				return null;
			}
			return searchConfiguration.list;
		},
		selectedList() {
			if (!this.selectedListId) {
				return null;
			}
			return this.$store.getters['list/byId'](this.selectedListId);
		},
		baseListSelected() {
			return this.baseListId === this.selectedListId;
		},
		historyDate() {
			return this.searchConfiguration && this.searchConfiguration.searchHistoryDate ? this.searchConfiguration.searchHistoryDate : null;
		},
		language() {
			return this.$store.state.search.selectedLocale;
		}
	},

	watch: {
		searchResults() {
			this.loadRelated();
		}
	},

	created() {
		this.setFields();
		this.restoreSort();
	},

	methods: {
		async loadRelated() {
			let categories = [];
			this.searchResults.forEach(product => {
				const allCategories = JSON.parse(JSON.stringify(this.extractDataFromList(product.data.listData, '_categoryTree', true)));
				categories = categories.concat(allCategories);
			});
			await this.$store.dispatch('category/loadEntitesById', categories);
		},
		getMainCategoryId(item) {
			const allCategories = JSON.parse(JSON.stringify(this.extractDataFromList(item.data.listData, '_categoryTree', true)));
			const mainCategory = this.$store.getters['category/mainCategoryFromIds'](allCategories);
			return mainCategory ? mainCategory.id : null;
		},
		restoreSort() {
			const sort = this.$store.getters['search/getSort'];
			if (!sort) {
				return;
			}
			this.sort.column = sort.column;
			this.sort.desc = sort.order === 'desc';
		},
		onUpdatePagination(startItem) {
			this.$store.commit('search/setStartItem', startItem);
			this.$emit('search');
		},
		setFields() {
			this.fields = [
				{
					label: this.$t('results.productTitle'),
					key: 'name',
					sortable: true
				},
				{
					label: this.$t('results.distributor'),
					key: 'distributor',
					sortable: true
				},
				{
					label: this.$t('results.category'),
					key: 'category',
					sortable: false
				}
			];

			if (this.selectedListId === this.baseListId || this.$store.state.search.euMode) {
				this.fields.push({
					label: this.$t('results.lists'),
					key: 'lists'
				});
			}
		},
		extractField(obj, field) {
			return extractField(obj, field);
		},
		extractDataFromList(baseData, field, useFallback) {
			const selectedListId = this.selectedListId;

			return extractDataFromList(baseData, field, selectedListId, useFallback ? this.baseListId : null);
		},
		getParametersForProductDetails(productId, listId) {
			return {
				name: 'SingleSearchResult',
				params: {
					product_id: productId,
					list_id: listId,
					locale: this.language,
					date: this.historyDate
				}
			};
		},
		handleSort(ctx) {
			if (!ctx.sortBy) {
				return;
			}

			this.sort.column = ctx.sortBy;
			this.sort.desc = ctx.sortDesc;

			const sort = {
				column: ctx.sortBy,
				order: ctx.sortDesc ? 'desc' : 'asc',
				listId: this.baseListId
			};

			this.$store.commit('search/setSort', sort);
			this.$emit('search');
		},
		getListEntries(item) {
			const keys = Object.keys(item.data.listData);
			let allLists = this.$store.getters['list/byIdsUnfiltered'](keys);
			if (this.baseListSelected || this.$store.state.search.euMode) {
				allLists = allLists.filter(f => f.data.targetAudience !== 'hobby');
				let listOptions = SearchOptionsProvider.getListOptions();
				allLists = allLists.filter(list => listOptions.find(listOption => {
					return listOption.id === list.id && this.$store.getters['approvals/isApprovedForList'](item.data.approvalHistory, list.id, this.historyDate);
				}));
			}
			allLists = allLists.filter(f => {
				const list = item.data.listData[f.id];
				return !list._preventPublicDisplay;
			});
			let listIds = allLists.map(f => f.id);
			let lists = listIds.map(listId => {
				return {
					deactivatedOn: '',
					key: listId
				};
			});

			return lists;
		},
		getName(row) {
			let historic = '';
			if (this.historyDate) {
				historic = getHistoricName(row.item, this.selectedListId, new Date(this.historyDate), this.language);
			}
			let latest = fieldToLocale(this.extractDataFromList(row.item.data.indexData, 'name', true));
			// console.log({ historic, latest })
			if (historic && latest && historic !== latest) {
				return this.$t('results.formerName', [latest, historic]);
			}
			return latest;

		}
	}
};
</script>
