
export function alphanumericSort(a, b) {
	if (a.text < b.text) {
		return -1;
	}

	if (a.text > b.text) {
		return 1;
	}

	return 0;
}
