<template>
	<div>
		<b-button v-if="!isLoggedIn" 
			variant="info"
			class="ml-2"
			@click="gotoLogin">
			{{ this.$t('paiduser.registration.login') }}
		</b-button>
		<b-dropdown :text="loginText"
			right
			variant="info"
			class="ml-2"
			v-else
		>
			<template v-if="isLoggedIn">
				<b-dropdown-group
					:header="$t('menu.account')"
				>
					<template v-if="accountName">
						<b-dropdown-text>
							{{ accountName }}
						</b-dropdown-text>
						<b-dropdown-item
							:to="{ name: 'UserCenter' }"
						>
							<b-icon-gear-fill scale="0.8" /> {{ $t('menu.profile') }}
						</b-dropdown-item>
					</template>
					<template v-else>
						<b-dropdown-item
							:to="{ name: 'Registration' }"
							variant="primary"
						>
							{{ $t('paiduser.registration.options') }}
						</b-dropdown-item>
					</template>
					<b-dropdown-item-button
						variant="secondary"
						@click="goToKeycloakAccountUrl"
					>
						{{ $t('menu.editKeycloakData') }}
					</b-dropdown-item-button>
					<b-dropdown-item-button
						@click="logout"
						variant="secondary"
					>
						<b-icon-door-closed scale="0.9" /> {{ $t('menu.logout') }}
					</b-dropdown-item-button>
					<b-dropdown-group
						:header="$t('menu.switchTo')"
						v-if="switchableAccounts.length > 0"
					>
						<b-dropdown-item
							v-for="acc in switchableAccounts"
							:key="acc.id"
							@click="switchAccount(acc)"
						>
							{{ acc | toAccountName }}
							({{ acc.data.staticData.role }})
						</b-dropdown-item>
					</b-dropdown-group>
				</b-dropdown-group>
				<div v-if="isCompany" >
					<b-dropdown-group
						:header="$t('menu.otherFunctions')"
					>
						<b-dropdown-item-button v-if="isCompany" @click="showArchiveModal">
							<b-card-text>
								{{ $t('paiduser.ui.pdfsAsArchive') }}
							</b-card-text>
							<b-modal ref="archive" 
								size="xl"
								ok-only
								ok-variant="secondary"
								:ok-title="$t('searchForm.pdfArchive.okayTitle')"
								:title="$t('paiduser.ui.pdfsAsArchive')"
							>
								<archive-tab />
							</b-modal>
						</b-dropdown-item-button>
					</b-dropdown-group>
				</div>
			</template>

		</b-dropdown>
	</div>
</template>


<script>
import ArchiveTab from '@/components/SearchResult/ArchiveTab.vue';

export default {
	name: 'PaidLogin',

	components: {
		ArchiveTab
	},

	data() {
		return {
		};
	},
	async created() {
		await this.$store.dispatch('auth/token/initApp');
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters['auth/token/isLoggedIn'];
		},
		account() {
			return this.$store.getters['auth/token/account'];
		},
		accountName() {
			return this.$store.getters['auth/user/accountName'];
		},
		switchableAccounts() {
			return this.$store.getters['auth/user/availableAccounts'].filter(e => e.id !== this.account);
		},
		loginText() {
			return this.isLoggedIn ?
				this.accountName ? this.$t('paiduser.registration.loggedIn', { userName: this.accountName }) : this.$t('paiduser.registration.unregistered')
				: this.$t('paiduser.registration.login');
		},
		isCompany() {
			return this.$store.getters['auth/user/hasCompanyType'];
		}
	},
	methods: {
		async gotoLogin() {
			this.$store.commit('auth/token/setManualLogin', true);
			await this.$store.dispatch('auth/token/initApp');
			await this.$store.dispatch('auth/token/authenticate');
		},
		logout() {
			this.$store.dispatch('auth/token/logout');
		},
		switchAccount(account) {
			this.$store.dispatch('auth/token/switchAccountAndLogin', account.id);
		},
		unregister() {
			this.$store.dispatch('auth/user/unregisterFromAccount');
		},
		showArchiveModal() {
			this.$refs.archive.show();
		},
		goToKeycloakAccountUrl() {
			const url = this.$store.getters['auth/token/getKeycloakAccountUrl'];
			window.location.href = url;
		}
	}
};
</script>
