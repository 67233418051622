<template>
	<b-container v-if="isLoggedIn">
		<h1>
			{{ $t('paiduser.termsOfService.header') }}
		</h1>
		<p>
			{{ $t('paiduser.termsOfService.tosMandatoryMessage') }}
		</p>
		<p class="font-weight-normal">
			{{ $t('paiduser.termsOfService.tosLink') }}
			<a href="https://www.fibl.org/en" target="_blank">{{ $t('paiduser.termsOfService.tosLinkLabel') }}</a>
		</p>
		<b-button @click="acceptTos()" variant="primary">
			{{ $t('paiduser.termsOfService.acceptTos') }}
		</b-button>
	</b-container>
	<b-container v-else>
		<p>
			{{ $t('paiduser.termsOfService.loginRequired') }}
		</p>
		<b-button
			variant="primary"
			@click="gotoLogin"
		>
			{{ $t('paiduser.termsOfService.toLogin') }}
		</b-button>
	</b-container>
</template>

<script>
import EventBus from '@/util/eventbus.js';

export default {
	name: 'AcceptTos',

	computed: {
		isLoggedIn() {
			return this.$store.getters['auth/token/isLoggedIn'];
		}
	},

	created() {
		if (this.isLoggedIn && this.$store.getters['auth/user/hasTosAccepted']) {
			EventBus.$emit('navigate', { name: 'PublicSearch' });
		}
	},

	methods: {
		gotoLogin() {
			this.$store.dispatch('auth/token/authenticate');
		},
		async acceptTos() {
			await this.$store.dispatch('auth/user/setTosAccepted');
			EventBus.$emit('success', this.$t('paiduser.messages.operationSuccessful'), this.$t('paiduser.messages.tosAcceptSuccessful'));
			EventBus.$emit('navigate', { name: 'PublicSearch' });
		}
	}
};
</script>
