<template>
	<div id="tree-display">
		<div v-for="(entry, index) in allEntries" :key="entry">
			<div v-if="isRoot(entry)">
				<div class="d-flex justify-content-between">
					<strong>
						{{ $t('tree.mainEntry') }}:
					</strong>
					<slot name="link-checkbox" />
				</div>
				{{ translateDisplay(entry) }}
				<strong v-if="hasChildrenSelected(entry)">
					<br>
					{{ $t('tree.subEntries') }}:
				</strong>
			</div>
			<span v-else-if="!hasChildrenSelected(entry)">
				{{ translateDisplay(entry) }}<span v-if="!isLastChild(index)">,</span><br v-else>
			</span>
		</div>
	</div>
</template>

<script>
import { treeDisplay } from '@/util/select-mapping.js';
import {getTotalSelection} from '@/util/tree';

export default {
	name: 'TreeSelectionDisplay',

	props: {
		selectedEntries: {},
		prefix: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			allEntries: []
		};
	},

	computed: {
		rootLevel() {
			if (this.prefix === 'component') {
				return 1;
			}

			return 0;
		}
	},

	watch: {
		selectedEntries(nv, ov) {
			let workingData = JSON.parse(JSON.stringify(nv));
			this.calculateTotalSelection(workingData);
		}
	},

	mounted() {
		let workingData = JSON.parse(JSON.stringify(this.selectedEntries));
		this.calculateTotalSelection(workingData);
	},

	methods: {
		isRoot(id) {
			// shift display, if necessary
			if (this.rootLevel > 0) {
				// check level
				const level = this.$store.getters[`${this.prefix}/getLevel`](id);
				return this.rootLevel === level;
			}

			const entry = this.$store.getters[`${this.prefix}/byId`](id);
			return typeof entry.data.parent === 'undefined';
		},
		getEntryById(id) {
			return this.$store.getters[`${this.prefix}/byId`](id);
		},
		hasChildrenSelected(id) {
			for (let currentEntry of this.allEntries) {
				let closestRoot = this.getEntryById(currentEntry);
				while (typeof closestRoot.data.parent !== 'undefined') {
					closestRoot = this.getEntryById(closestRoot.data.parent);
					if (closestRoot.id === id) {
						return true;
					}
				}
			}

			return false;
		},
		isLastChild(index) {
			let temp = Array.from(this.allEntries);

			if (index + 1 > temp.length) {
				return true;
			}

			const nextId = temp[index + 1];
			if (typeof nextId !== 'undefined') {
				return this.isRoot(nextId);
			}

			return true;
		},
		translateDisplay(entry) {
			return treeDisplay(this.prefix, entry);
		},
		calculateTotalSelection(workingData) {
			if (!workingData) {
				this.allEntries = [];
				return;
			}

			this.allEntries = Array.from(getTotalSelection(this.prefix, workingData));
		}
	}
};
</script>
