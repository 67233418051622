import Vue from 'vue';
import moment from 'moment';
import { fieldToLocale } from '@/util/locale';
import { toListDisplay, biostandardDisplay, countryDisplay, categoryDisplay, selectOptionDisplay } from '@/util/select-mapping';
import Store from '@/store/index';

Vue.filter('fieldToLocale', fieldToLocale);
Vue.filter('toListDisplay', toListDisplay);
Vue.filter('fieldToLocaleList', (arr) => {
	return arr.map(fieldToLocale).join(', ');
});

export function toDateFormat(date) {
	const obj = date;
	if (!obj) {
		return '';
	}
	const locale = Store.getters['search/getLocale'];
	return moment(date).locale(locale).format('L');
}

Vue.filter('toDateFormat', toDateFormat);
Vue.filter('toBiostandardDisplay', biostandardDisplay);
Vue.filter('toCountryDisplay', countryDisplay);
Vue.filter('toCategoryDisplay', categoryDisplay);
Vue.filter('toSelectOptionDisplay', selectOptionDisplay);

export function toAccountName(v) {
	if (v.data) v = v.data;
	return v?.userData?.mainAddress?.name;
}

Vue.filter('toAccountName', toAccountName);
