import Store from '@/store/index';

export class DisplayOptionsProvider {
	static async initialize({ categoryIds, componentIds }) {
		await Promise.all([
			Store.dispatch('list/loadAll', { ifEmpty: true }),
			Store.dispatch('field/loadAll'),
			Store.dispatch('category/loadEntitesById', categoryIds),
			Store.dispatch('component/loadEntitesById', componentIds),
			Store.dispatch('biostandard/loadAll', { ifEmpty: true }),
			Store.dispatch('permit/loadAll', { ifEmpty: true }),
			// Store.dispatch('selectoptions/loadAll', { ifEmpty: true })
		]);
	}
}
