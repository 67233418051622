import Store from '@/store/main.js';

Store.watch(
	() => {
		let d = JSON.stringify(Store.getters['searchUi/noCategory']);
		// console.log('search/noCategory', d);
		return d;
	},
	() => {
		Store.commit('category/resetCounts');
	}
);

Store.watch(
	() => JSON.stringify(Store.getters['searchUi/noComponent']),
	() => {
		Store.commit('component/resetCounts');
	}
);
