<template>
	<div>
		<h4>
			{{ $t('singleResult.default.product.title') }}
		</h4>

		<history-table-vue
			:product-data="productData"
			:list-id="listId"
			:history-date="historyDate"
			v-if="historyDate"
		/>

		<h4 v-if="historyDate">
			{{ $t('singleResult.default.product.titleHistory', { date: historyDateFormatted }) }}
		</h4>
		<table v-if="distributor" class="table">
			<tbody>
				<tr>
					<td class="w-25">
						{{ $t('singleResult.hobby.distributor') }}:
					</td>
					<td>
						{{ companyName }}<br>
						{{ extractField(address, 'street') }}<br>
						{{ extractField(address, 'zip') }} {{ extractField(address, 'city') }}<br>
						{{ extractField(address, 'country') | toCountryDisplay }}
					</td>
					<td>
						{{ $t('singleResult.default.distributor.phoneShort') }} {{ extractField(address, 'phone') || '-' }}<br>
						{{ $t('singleResult.default.distributor.faxShort') }} {{ extractField(address, 'fax') || '-' }}<br>
						{{ $t('singleResult.default.distributor.emailShort') }} <a :href="`mailto:${extractField(address, 'email')}`">{{ extractField(address, 'email') }}</a><br>
						{{ $t('singleResult.default.distributor.websiteShort') }} <a :href="website" target="_blank">{{ website }}</a>
					</td>
				</tr>
			</tbody>
		</table>
		<table class="table mt-3">
			<tbody>
				<tr>
					<td class="w-25">
						{{ $t('singleResult.hobby.product') }}:
					</td>
					<td>
						{{ extractDataFromIndex('name') | fieldToLocale }}
					</td>
				</tr>
				<tr>
					<td>
						{{ $t('singleResult.hobby.application') }}:
					</td>
					<td>
						{{ application | fieldToLocale }}
					</td>
				</tr>
				<tr>
					<td>
						{{ $t('singleResult.hobby.comment') }}:
					</td>
					<td>
						{{ comment | fieldToLocale }}
					</td>
				</tr>
				<tr v-if="selectedMainCategory !== 'plant-protection-agents'">
					<td>
						{{ $t('singleResult.hobby.components') }}:
					</td>
					<td>
						{{ components.join(', ') || '-' }}
					</td>
				</tr>
				<template v-else>
					<tr>
						<td>
							{{ $t('singleResult.hobby.substances') }}:
						</td>
						<td>
							<template v-if="substances.length > 0">
								<span class="nutrientEntry"
									v-for="(row,i) in substances"
									:key="JSON.stringify([row,i])"
								>{{ row.type }}: {{ row.amount }} {{ row.unit }}</span>
							</template>
							<template v-else>
								-
							</template>
						</td>
					</tr>
					<tr>
						<td>
							{{ $t('singleResult.hobby.harmfulOrganism') }}:
						</td>
						<td>
							<template v-if="psmData.length > 0">
								{{ psmData.join(', ') }}
							</template>
							<template v-else>
								-
							</template>
						</td>
					</tr>
				</template>
				<tr v-if="selectedMainCategory === 'fertilisers'">
					<td>
						{{ $t('singleResult.hobby.nutrients') }}:
					</td>
					<td>
						<template v-if="nutrients.length > 0">
							<span class="nutrientEntry"
								v-for="(nutrientRow,i) in nutrients"
								:key="JSON.stringify([nutrientRow,i])"
							>{{ nutrientRow.type }}: {{ nutrientRow.amount }} {{ nutrientRow.unit }}</span>
						</template>
						<template v-else>
							-
						</template>
					</td>
				</tr>
				<tr>
					<td>
						{{ $t('singleResult.hobby.purchasing') }}:
					</td>
					<td>
						{{ extractDataFromList('bg-f3') | fieldToLocale }}<template v-if="Object.keys(extractDataFromList('bg-f4')).length > 0">
							; {{ unwrapValue(extractDataFromList('bg-f4')) | fieldToLocale }}
						</template>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { unwrapValue, fieldToLocale, extractDataFromList, extractField, NO_RESULT} from '@/util/locale';
import { getTotalSelection } from '@/util/tree';
import { selectOptionDisplay } from '@/util/select-mapping';
import { toDateFormat } from '@/plugins/filters';
import HistoryTableVue from './HistoryTable.vue';

export default {
	name: 'HobbySearchResult',

	components: {
		HistoryTableVue
	},

	props: {
		productData: {
			type: Object,
			required: true
		},
		distributor: {
			type: Object
		},
		mainAddress: {
			type: Object
		}
	},

	computed: {
		website() {
			let val = this.extractField(this.address, 'website');
			if (!val) {
				val = this.distributor.data.baseData.website;
			}
			return val;
		},
		companyName() {
			if (this.address.additionalNameIsSet) {
				return extractField(this.address, 'name');
			} else {
				return this.distributor.data.baseData.name;
			}
		},
		standards() {
			if (!this.selectedListData) {
				return [];
			}

			return this.selectedListData._approval || [];
		},
		allCategories() {
			const categories = this.extractDataFromList('_categoryTree');

			if (!Array.isArray(categories)) {
				return [];
			}

			return categories;
		},

		address() {
			return this.mainAddress;
		},

		selectedMainCategory() {
			const categories = Array.from(getTotalSelection('category', this.allCategories));
			const category = this.$store.getters['category/mainCategoryFromIds'](categories);

			return category ? category.data.mainCategoryKey : '';
		},
		subCategories() {
			const categories = this.$store.getters['category/byIds'](this.allCategories);

			return categories
				.filter(f => !!f.data.parent)
				.map(f => fieldToLocale(f.data.title));
		},
		productId() {
			return this.$route.params.product_id;
		},
		listId() {
			return this.$route.params.list_id;
		},
		baseListId() {
			return this.$store.getters['list/baseListId'];
		},
		listData() {
			if (!this.productData) {
				return null;
			}

			return this.productData.data.listData;
		},
		selectedListData() {
			const listData = this.listData;

			if (listData) {
				return listData[this.listId];
			}

			return null;
		},
		baseListData() {
			const listData = this.listData;

			if (listData) {
				return listData[this.baseListId];
			}

			return null;
		},
		indexData() {
			if (!this.productData) {
				return null;
			}

			const productDataUnwrapped = this.productData.data;
			if (productDataUnwrapped.indexData) {
				return productDataUnwrapped.indexData;
			}

			return null;
		},
		baseIndexData() {
			const indexData = this.indexData;

			if (indexData) {
				return indexData[this.baseListId];
			}

			return null;
		},
		selectedIndexData() {
			const indexData = this.indexData;

			if (indexData) {
				return indexData[this.listId];
			}

			return null;
		},
		components() {
			if (!this.productData) {
				return [];
			}
			const index = this.productData.data.componentIndex;
			let res = index.filter(f => f._listId === this.listId);
			if (res.length === 0) res = index.filter(f => f._listId === this.baseListId);
			return res.map(f => fieldToLocale(f.printlabel));
		},
		substances() {
			const data = this.extractTableData('tab-wkst', 'wkst-s1', true);
			const options = this.$store.getters['field/optionsByFieldPath']('wkst-wkst');
			const units = this.$store.getters['field/optionsByFieldPath']('wkst-einheit');
			return data.map(f => {
				let type = fieldToLocale(f['wkst-detail']);
				if (!type) {
					let typeId = unwrapValue(f['wkst-wkst']);
					let typeObj = options.find(e => e.id === typeId);
					type = typeObj ? fieldToLocale(typeObj.title) : '';
				}
				let amount = unwrapValue(f['wkst-gehalt']);
				let unitId = unwrapValue(f['wkst-einheit']);
				let unitObj = units.find(e => e.id === unitId);
				let unit = unitObj ? fieldToLocale(unitObj.title) : '';
				return {
					type,
					amount,
					unit
				};
			});
		},
		nutrients() {
			const data = this.extractTableData('tab-nst', 'nst-s1', true);
			const units = this.$store.getters['field/optionsByFieldPath']('nst-s4');
			return data.map(f => {
				let type = selectOptionDisplay(unwrapValue(f['nst-s2']));
				let amount = unwrapValue(f['nst-s3']);
				let unitId = unwrapValue(f['nst-s4']);
				let unitObj = units.find(e => e.id === unitId);
				let unit = unitObj ? fieldToLocale(unitObj.title) : '';
				return {
					type,
					amount,
					unit
				};
			});
		},
		psmData() {
			const data = this.extractTableData('tab-psm', null, true);
			return data.map(f => `${fieldToLocale(f['psm-s2'])}: ${fieldToLocale(f['psm-s3'])}`);
		},
		comment() {
			const listData = this.selectedListData;
			if (!listData) {
				return null;
			}

			const comment = listData['bg-f1'];

			return comment;
		},
		application() {
			const listData = this.selectedListData;
			if (!listData) {
				return null;
			}

			const application = listData['bg-f2'];

			return application;
		},
		historyDate() {
			return this.$route.params.date;
		},
		historyDateFormatted() {
			if (!this.historyDate) return '';
			return toDateFormat(this.historyDate);
		}
	},

	async created() {
		await this.$store.dispatch('field/loadAll');
		await this.ensureSelectOptionsInCache();
	},

	methods: {
		extractDataFromList(field) {
			if (!this.listData) {
				return NO_RESULT;
			}

			const selectedListId = this.listId;
			const baseListId = this.baseListId;
			const baseData = this.listData;

			return extractDataFromList(baseData, field, selectedListId, baseListId);
		},
		extractDataFromIndex(field) {
			if (!this.indexData) {
				return NO_RESULT;
			}

			const selectedListId = this.listId;
			const baseListId = this.baseListId;
			const baseData = this.indexData;

			return extractDataFromList(baseData, field, selectedListId, baseListId);
		},
		extractField(obj, field) {
			const value = extractField(obj, field);
			if (value === NO_RESULT) {
				return null;
			}

			return value;
		},
		unwrapValue(obj) {
			return unwrapValue(obj);
		},
		extractTableData(tablePath, printFilter, useFallback) {
			const _unwrapTableData = function(base, tablePath) {
				if (!base) {
					return [];
				}

				let tableData = base[tablePath];

				if (!tableData || !tableData.table) {
					return [];
				}

				return tableData.table;
			};

			let tableData = _unwrapTableData(this.selectedListData, tablePath);

			if (useFallback && (!tableData || tableData.length === 0)) {
				tableData = _unwrapTableData(this.baseListData, tablePath);
			}

			if (printFilter) {
				return tableData.filter(f => f[printFilter] && f[printFilter].booleanValue === true);
			}

			return tableData;
		},
		async ensureSelectOptionsInCache() {
			await this.$store.dispatch('selectoptions/ensureOptionsInCache', this.productData);
		}

	}
};
</script>
