import { search, getEntityclassByExternalId } from '@/repository/system.js';
import { fieldToLocale } from '@/util/locale.js';

const state = {
	countries: [],
	entityClassId: null
};

const getters = {
	byId: (state) => (id) => {
		return state.countries.find(country => country.id === id);
	},
	all: (state) => {
		return state.countries;
	},
	allSorted: (state) => {
		return state.countries.sort((a, b) => {
			return fieldToLocale(a.data.title).localeCompare(fieldToLocale(b.data.title));
		});
	}
};

const actions = {

	async loadAll({ commit, state }, { ifEmpty }) {
		if (ifEmpty && state.countries.length > 0) return;

		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId('country');
			commit('setEntityClassId', entityClass);
		}

		let list = await search(state.entityClassId);
		list = list.sort((a, b) => {
			return fieldToLocale(a.data.title).localeCompare(fieldToLocale(b.data.title));
		});
		commit('setEntries', list);
	}
};

const mutations = {

	setEntityClassId(state, {id}) {
		state.entityClassId = id;
	},

	setEntries(state, entries) {
		state.countries = entries;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
