<template>
	<div>
		<b-card :header="$t('searchForm.pdfArchive.title')">
			<b-card-text>
				{{ $t('searchForm.pdfArchive.cardDescription') }}
			</b-card-text>
			<b-form-group
				v-if="listOptions.length > 1"
			>
				<b-card-text class="font-weight-bold">
					{{ $t('searchForm.list') }}:
				</b-card-text>
				<b-form-select :options="listOptions"
					v-model="targetList"
					class="w-25"
				/>
			</b-form-group>
			<b-card-text>
				{{ $t('searchForm.pdfArchive.productsOfCompany') }}: {{ companyProductCount }}
			</b-card-text>
			<div v-if="companyProductCount">
				<b-card-text>
					{{ $t('searchForm.pdfArchive.timeEstimate') }}: {{ companyProductCount * 2 }}s
				</b-card-text>
				<b-progress
					:max="companyProductCount"
					show-value
					show-progress-animated
					:animated="working"
					:value="progress"
				>
					<b-progress-bar :value="progress" />
				</b-progress>
				<b-button @click="getPdfs" class="mt-4" :disabled="working" variant="primary" >Download</b-button>
			</div>
		</b-card>
	</div>
</template>


<script>
import { SearchOptionsProvider } from '@/services/SearchOptionsProvider';
import { fieldToLocale } from '@/util/locale';
import moment from 'moment';

export default {
	name: 'ArchiveTab',

	data() {
		return {
			progress: 0,
			working: false,
			limit: 500,
			companyProductCount: 0,
			targetList: null,
			company: null
		}
	},

	components: {

	},

	computed: {
		accountCompany() {
			if (!this.$store.state.auth.user.availableAccounts || !this.$store.state.auth.user.availableAccounts.length) return null;
			if (this.$store.state.auth.user.details
				&& this.$store.state.auth.user.details.active
				&& this.$store.state.auth.user.details.staticData.role === 'comp'
				&& this.$store.state.auth.user.details.staticData.companyLink) {
				return this.$store.state.auth.user.details.staticData.companyLink;
			}
			const companyAccount = this.$store.state.auth.user.availableAccounts.find(account => account.data.staticData && account.data.staticData.active && account.data.staticData.role === 'comp' && account.data.staticData.companyLink);
			return companyAccount ? companyAccount.data.staticData.companyLink : null;
		},
		companyName() {
			return this.company ? this.company.data.baseData.companyAbbreviation : '';
		},
		listOptions() {
			let allLists;
			if (!this.isEuMode) {
				allLists = SearchOptionsProvider.getListOptions();
			} else {
				allLists = SearchOptionsProvider.getListOptionsUnfiltered();
			}
			return allLists.map(obj => {
				return {
					value: obj.id,
					text: fieldToLocale(obj.data.title)
				};
			});
		},
		listName() {
			let list = this.$store.getters['list/byId'](this.targetList);
			return list && list.data.title ? list.data.title : '';
		},
		companySearchConfiguration() {
			if (!this.targetList || !this.accountCompany) return null;
			let search = this.$store.getters['search/getDefaultConfig']();
			search.list = this.targetList;
			search.distributor = this.accountCompany;
			return search;
		},
		currentDate() {
			return moment().format('YYMMDD');
		}
	},

	watch: {
		async targetList(nv, ov) {
			if (nv !== ov) {
				this.companyProductCount = await this.getCompanyProductCount();
			}
		}
	},

	async created() {
		if (this.$store.state.search.listFilter && this.$store.state.search.listFilter.length === 1) {
			this.targetList = this.$store.state.search.listFilter[0];
		} else if (this.$store.state.search.lastSearch && this.$store.state.search.lastSearch.list) {
			this.targetList = this.$store.state.search.lastSearch.list;
		} else {
			this.targetList = this.$store.getters['list/baseListId'];
		}
		this.companyProductCount = await this.getCompanyProductCount();
		if (this.accountCompany) {
			[ this.company ] = await this.$store.dispatch('companylist/loadByIds', [ this.accountCompany ]);
		}
	},

	methods: {
		async getCompanyProductCount() {
			if (!this.companySearchConfiguration) return 0;
			let count = await this.$store.dispatch('search/searchForArchive', { limit: this.limit, searchConfiguration: this.companySearchConfiguration, countOnly: true } );
			return count;
		},
		async getProductFileNameFromId(product) {
			let productName = '';
			if (product && product.data.indexData) {
				let index = product.data.indexData;
				productName = index[this.targetList] && index[this.targetList].name ? index[this.targetList].name : index[this.baseListId].name;
			}
			return `${fieldToLocale(productName)}_${fieldToLocale(this.listName).replace('/', '-')}_${this.currentDate}.pdf`;
		},
		async getPdfs() {
			if (!this.targetList || !this.company) return;
			try {
				this.working = true;
				this.progress = 0;
				let products = await this.$store.dispatch('search/searchForArchive', { limit: this.limit, searchConfiguration: this.companySearchConfiguration } );
				let pdfs = [];
				let zipFileName = `${this.companyName}_${this.$t('searchForm.pdfArchive.listingConfirmation')}_${this.currentDate}`;
				this.$store.commit('pdf/setArchiveWorking', true);
				for (let i=0; i<products.length; i++) {
					const product = products[i];
					let pdf = await this.$store.dispatch('pdf/getPdfWithoutDownload', { productId: product.id, listId: this.targetList, language: this.$store.state.search.selectedLocale });
					let fileName = await this.getProductFileNameFromId(product);
					this.progress = i+1;
					pdfs.push({ pdf, fileName });
				}
				await this.$store.dispatch('pdf/getArchiveFromPdfs', { pdfs, zipFileName });
			} finally {
				this.$store.commit('pdf/setArchiveWorking', false);
				this.working = false;
			}
		}
	}

};
</script>
