
/*
 * extra cache for setting the current JWT
 * needed because the request.js cannot require the store
 * without creating a recursive dependency
 */
let currentToken = null;

export function getToken() {
	// console.log('getToken', currentToken && currentToken.length > 0);
	return currentToken;
}

export function setToken(t) {
	// console.log('setToken', !!t);
	currentToken = t;
}


let usePaidRoute = false;

export function setUsePaidRoute(p) {
	usePaidRoute = p;
}

export function getUsePaidRoute() {
	return usePaidRoute;
}

export function getPubRoute() {
	return usePaidRoute ? 'paid' : 'pub';
}