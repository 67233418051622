import { getEntityclassByExternalId } from '@/repository/system.js';
import { search } from '@/repository/system';
import { FALLBACK_LOCALE } from '@/store/search';

const state = {
	lists: [],
	baseListId: null,
	entityClassId: ''
};

const CLASS_ID = 'list';

const getters = {
	lists: (state, getters, rootState) => {
		const listFilter = rootState.search.listFilter;
		if (!listFilter || listFilter.length === 0) {
			return state.lists;
		}
		return state.lists.filter(f => listFilter.includes(f.id));
	},
	listsUnfiltered: (state) => {
		return state.lists;
	},
	byId: (state, getters) => (id) => {
		return getters.listsUnfiltered.find(f => f.id === id);
	},
	byIdUnfiltered: (state, getters) => (id) => {
		return getters.listsUnfiltered.find(f => f.id === id);
	},
	byIds: (state, getters) => (ids) => {
		return getters.lists
			.filter(val => ids.includes(val.id));
	},
	byIdsUnfiltered: (state, getters) => (ids) => {
		return getters.listsUnfiltered.filter(val => ids.includes(val.id));
	},
	baseListId: (state) => {
		return state.baseListId;
	},
	baseStandardsByListId: (state) => (id) => {
		const list = state.lists.find(f => f.id === id);
		if (!list) return [];
		return list.data.baseStandards || [];
	},
	baseStandardsForAllLists: (state) => () => {
		const baseStandards = [];
		for (const list of state.lists) {
			if (list.data.baseStandards && list.data.baseStandards.length) {
				list.data.baseStandards.forEach(baseStandard => {
					if (!baseStandards.includes(baseStandard)) baseStandards.push(baseStandard);
				});
			}
		}
		return baseStandards;
	},
	standardsByListId: (state) => (id) => {
		const list = state.lists.find(f => f.id === id);
		if (!list) return [];
		return list.data.biostandards.concat(list.data.baseStandards || []);
	},
	fallbackLocaleByListId: (state) => (id) => {
		const list = state.lists.find(f => f.id === id);
		if (!list) return FALLBACK_LOCALE;
		return list.data.fallbackLanguage;
	},
	isHobbyList: (state) => (id) => {
		const list = state.lists.find(f => f.id === id);
		if (!list) return false;
		return list && list.data.targetAudience && list.data.targetAudience === 'hobby';
	}
};

const actions = {
	async _loadEntityClass({ commit }) {
		if (!state.entityClassId) {
			let ec = await getEntityclassByExternalId(CLASS_ID);
			commit('setEcId', { id: ec.id });
		}
	},

	async loadAll({ commit, dispatch }, { ifEmpty }) {
		if (ifEmpty && state.lists.length > 0) return;
		await dispatch('_loadEntityClass');
		const result = await search(state.entityClassId);
		commit('setResult', result);
		commit('setBaseListId');
	}
};

const mutations = {
	setResult(state, result) {
		if (Array.isArray(result) && result.length) {
			result = result.sort((a, b) => {
				let valA = a.data.order || 0;
				let valB = b.data.order || 0;
				if (valA === valB) {
					return 0;
				} else {
					return valA < valB ? -1 : 1;
				}
			});
		}
		state.lists = result;
	},
	setBaseListId(state) {
		const lists = state.lists.filter(f => f.data.isBasedata);
		if (lists.length === 0) return;
		state.baseListId = lists[0].id;
	},
	setEcId(state, { id }) {
		state.entityClassId = id;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
