<template>
	<b-form-group>
		<tree-selection-display
			:selected-entries="selectedEntries"
			:prefix="prefix"
		/>
		<b-link @click="showSelection">
			{{ getTitle }}
		</b-link>
		<b-link @click="clearSelection" v-show="selectedEntries.length > 0">
			{{ $t('tree.reset') }}
		</b-link>
		<b-modal ref="treeSelectionModal"
			:title="$t('tree.select')"
			size="xl"
			static
			lazy
		>
			<b-row>
				<tree-selection-root
					v-model="selectedEntries"
					:only-root-level-selection="false"
					:enable-multi-select="multiSelect"
					:enable-single-root-selection="rootSingleSelect"
					:enable-root-selection="true"
					:prefix="prefix"
					:root-filter="rootFilter"
					:root-expanded="rootExpanded"
				/>
			</b-row>
			<div slot="modal-footer">
				<b-button variant="success" @click="closeSelection">
					{{ $t('tree.close') }}
				</b-button>
			</div>
		</b-modal>
	</b-form-group>
</template>

<script>
import TreeSelectionRoot from './TreeSelectionRoot.vue';
import TreeSelectionDisplay from './TreeSelectionDisplay.vue';
import { SearchOptionsProvider } from '@/services/SearchOptionsProvider';

export default {
	name: 'TreeSelectWrapper',

	components: {
		TreeSelectionDisplay,
		TreeSelectionRoot
	},

	props: {
		value: {
			required: false
		},
		prefix: {
			type: String,
			required: false,
			default: 'component'
		},
		path: {
			type: String,
			required: false,
			default: () => ''
		},
		canLink: {
			type: Boolean,
			required: false,
			default() {
				return null;
			}
		},
		readonly: {
			type: Boolean,
			required: false,
			default() {
				return null;
			}
		},
		rootFilter: {
			type: Array,
			default: () => []
		},
		rootExpanded: {
			type: Boolean,
			default: false,
			required: false
		},
		multiSelect: {
			type: Boolean,
			required: false,
			default: true
		},
		rootSingleSelect: {
			type: Boolean,
			required: false,
			default: true
		}
	},

	computed: {
		totalPath() {
			return this.path ? this.path : this.prefix;
		},
		selectedEntries: {
			get() {
				if (!Array.isArray(this.value)) {
					return [ this.value ];
				}

				return this.value;
			},
			set(v) {
				this.$emit('input', Array.from(v));
			}
		},
		getTitle() {
			return this.prefix === 'category' ? this.$t('tree.selectCategory') : this.$t('tree.select');
		}
	},

	methods: {
		async showSelection() {
			await SearchOptionsProvider.initializeTree({ type: this.prefix });
			this.$refs.treeSelectionModal.show();
		},
		closeSelection() {
			this.$refs.treeSelectionModal.hide();
		},
		clearSelection() {
			this.selectedEntries = [];
		}
	}
};
</script>
