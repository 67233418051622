<template>
	<div>
		<div class="mt-2 mb-4">
			<span class="drawStar">
				{{ $t('paiduser.editUserData.requiredField1') }}
			</span>
			<span>
				{{ $t('paiduser.editUserData.requiredField2') }}
			</span>
		</div>
		<div>
			<b-form-group class="font-weight-bold requiredStar" :label="$t('paiduser.editUserData.land')">
				<b-form-select
					v-model="mainCountry"
					:options="mainCountryOptions"
					:disabled="isEditData"
				/>
			</b-form-group>
		</div>
		<div v-if="mainCountry === 'DE'">
			<b-form-group class="font-weight-bold" :label="$t('paiduser.editUserData.controlBody')">
				<multi-select
					v-model="$v.form.controlBody.$model"
					:state="$v.form.controlBody.$dirty ? !$v.form.controlBody.$error : null"
					:options="controlBodyOptions"
					id-key="value"
					label-key="text"
					:searchable="true"
					:multiple="false"
					:close-on-select="true"
					:placeholder="$t('paiduser.editUserData.noControlBody')"
				/>
			</b-form-group>
			<b-form-group class="font-weight-bold" :label="$t('paiduser.editUserData.controlNumber')">
				<b-form-input
					v-model="$v.form.controlNumber.$model"
					:state="$v.form.controlNumber.$dirty ? !$v.form.controlNumber.$error : null"
					:placeholder="!isEditData ? $t('paiduser.editUserData.controlNumberFormat') : ''"
				/>
			</b-form-group>
			<b-form-group class="font-weight-bold" :label="$t('paiduser.registration.status.header')" v-if="statusMessage">
				<div>
					<label class="font-weight-normal">
						{{ statusMessage }}
					</label>
					<div v-if="associationNames">
						<label class="font-weight-normal">
							{{ $t('paiduser.registration.knownAssociations') }}: {{ associationNames }}
						</label>
					</div>
					<div v-show="isWorking" >
						<b-spinner small/>
						<label>
							&nbsp;{{ $t('paiduser.registration.status.fetchingData') }}...
						</label>
					</div>
				</div>
				<div>
					<b-button v-if="canCheckBioc" @click="checkBiocData" class="mt-2">
						{{ $t('paiduser.registration.checkControlNumber') }}
					</b-button>
				</div>
			</b-form-group>
			<b-form-group>
				<div>
					<b-button v-if="!proceedWithout && !this.isControlNumberValid && !isEditData" @click="toggleProceedWithout" class="mt-2">
						{{ $t('paiduser.registration.proceedWithoutOrganisation') }}
					</b-button>
				</div>
			</b-form-group>
		</div>
		<div v-if="isEditData && associationNames">
			<label class="font-weight-normal">
				{{ $t('paiduser.registration.knownAssociations') }}: {{ associationNames }}
			</label>
		</div><br>
		<div v-if="displayUserdataform">
			<b-form @submit.prevent="submit">
				<b-form-group class="font-weight-bold" :label="$t('paiduser.editUserData.mainAddress')">
					<b-form-group
						:label="$t('paiduser.editUserData.email')"
						class="font-weight-normal requiredStar"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
					<b-form-input
						v-model="$v.form.mainAddress.email.$model"
						:state="$v.form.mainAddress.email.$dirty ? !$v.form.mainAddress.email.$error : null"
					/>
					</b-form-group>
					<b-form-group
						class="font-weight-normal requiredStar"
						:label="$t('paiduser.editUserData.name')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.mainAddress.name.$model"
							:state="$v.form.mainAddress.name.$dirty ? !$v.form.mainAddress.name.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.street')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.mainAddress.street.$model"
							:state="$v.form.mainAddress.street.$dirty ? !$v.form.mainAddress.street.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.citycode')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.mainAddress.citycode.$model"
							:state="$v.form.mainAddress.citycode.$dirty ? !$v.form.mainAddress.citycode.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.city')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.mainAddress.city.$model"
							:state="$v.form.mainAddress.city.$dirty ? !$v.form.mainAddress.city.$error : null"
						/>
					</b-form-group>
					<b-form-group
						class="font-weight-normal requiredStar"
						:label="$t('paiduser.editUserData.countrycode')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-select
							v-model="$v.form.mainAddress.countrycode.$model"
							:options="countryCodes"
							:state="$v.form.mainAddress.countrycode.$dirty ? !$v.form.mainAddress.countrycode.$error : null"
						/>
					</b-form-group>
				</b-form-group>

				<!-- invoice address -->
				<b-form-group :label="$t('paiduser.editUserData.additionalInvoiceAddress')">
					<b-form-checkbox v-model="useAdditionalInvoiceAddress"
						:value="true"
					/>
				</b-form-group>

				<b-form-group v-if="useAdditionalInvoiceAddress"
					class="font-weight-bold"
					:label="$t('paiduser.editUserData.invoiceAddress')"
				>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.email')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.invoiceAddress.email.$model"
							:state="$v.form.invoiceAddress.email.$dirty ? !$v.form.invoiceAddress.email.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.name')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.invoiceAddress.name.$model"
							:state="$v.form.invoiceAddress.name.$dirty ? !$v.form.invoiceAddress.name.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.street')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.invoiceAddress.street.$model"
							:state="$v.form.invoiceAddress.street.$dirty ? !$v.form.invoiceAddress.street.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.citycode')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.invoiceAddress.citycode.$model"
							:state="$v.form.invoiceAddress.citycode.$dirty ? !$v.form.invoiceAddress.citycode.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.city')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.invoiceAddress.city.$model"
							:state="$v.form.invoiceAddress.city.$dirty ? !$v.form.invoiceAddress.city.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.countrycode')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-select
							v-model="$v.form.invoiceAddress.countrycode.$model"
							:options="countryCodes"
							:state="$v.form.invoiceAddress.countrycode.$dirty ? !$v.form.invoiceAddress.countrycode.$error : null"
						/>
					</b-form-group>
				</b-form-group>


				<b-form-group class="font-weight-bold" :label="$t('paiduser.editUserData.otherData')" v-if="isEditData">
					<!-- <b-form-group class="font-weight-normal"
						:label="$t('paiduser.editUserData.controlNumber')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
						v-if="form.mainAddress.countrycode !== 'DE'"
					>
						<b-form-input
							v-model="$v.form.controlNumber.$model"
							:state="$v.form.controlNumber.$dirty ? !$v.form.controlNumber.$error : null"
						/>
					</b-form-group> -->

					<b-form-group class="font-weight-normal"
						:label="$t('paiduser.editUserData.commentUser')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
						v-if="isEditData"
					>
						<b-form-input
							v-model="$v.form.commentUser.$model"
							:state="$v.form.commentUser.$dirty ? !$v.form.commentUser.$error : null"
						/>
					</b-form-group>

					<!-- <b-form-group class="font-weight-normal"
						:label="$t('paiduser.editUserData.taxId')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.taxId.$model"
							:state="$v.form.taxId.$dirty ? !$v.form.taxId.$error : null"
						/>
					</b-form-group> -->
				</b-form-group>

				<b-form-group :label="$t('paiduser.editUserData.contactPersonData')" v-if="!isEditData">
					<b-form-checkbox v-model="showContactPerson"
						:value="true"
					/>
				</b-form-group>

				<b-form-group class="font-weight-bold" :label="$t('paiduser.editUserData.contactPerson')" v-if="showContactPerson || isEditData">
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.firstName')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.contactPersonFirstname.$model"
							:state="$v.form.contactPersonFirstname.$dirty ? !$v.form.contactPersonFirstname.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.lastName')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.contactPersonLastname.$model"
							:state="$v.form.contactPersonLastname.$dirty ? !$v.form.contactPersonLastname.$error : null"
						/>
					</b-form-group>
					<b-form-group
						:class="paidClass"
						:label="$t('paiduser.editUserData.phone')"
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
					>
						<b-form-input
							v-model="$v.form.contactPhone.$model"
							:state="$v.form.contactPhone.$dirty ? !$v.form.contactPhone.$error : null"
						/>
					</b-form-group>
				</b-form-group>
				<b-form-group v-if="formType === 'registration'"
					:label="$t('paiduser.termsOfService.header')"
					:state="$v.form.tosAccepted.$dirty ? !$v.form.tosAccepted.$error : null"
					class="font-weight-bold"
				>
					<b-form-group label=""
						label-cols="4"
						label-cols-sm="3"
						label-cols-md="2"
						class="font-weight-normal"
					>
						<p class="font-weight-normal">
							{{ $t('paiduser.termsOfService.tosLink') }}
							<b-link @click="showAgbModal">
								{{ $t('paiduser.termsOfService.tosLinkLabel') }}
							</b-link>
						</p>
						<label class="form-inline">
							<b-form-checkbox
								v-model="$v.form.tosAccepted.$model"
								:value="true"
								:state="$v.form.tosAccepted.$dirty ? !$v.form.tosAccepted.$error : null"
							/>
							<span class="font-weight-normal">{{ $t('paiduser.termsOfService.readAndAcceptedTos') }}</span>
						</label>
						<p class="text-danger font-weight-nromal" v-show="$v.form.tosAccepted.$dirty && $v.form.tosAccepted.$error">
							{{ $t('paiduser.termsOfService.tosNotAccepted') }}
						</p>
					</b-form-group>
				</b-form-group>

				<b-button type="submit" variant="primary" :disabled="submitOnCooldown">
					{{ $t('paiduser.editUserData.submit') }}
				</b-button>
			</b-form>
		</div>
		<b-modal ref="agbModal"
			scrollable
			size="lg"
			ok-only
			:title="$t('paiduser.termsOfService.header')"
		>
			<b-card-text>
				{{ $t('paiduser.termsOfService.tosText1') }}<br><br>
				{{ $t('paiduser.termsOfService.tosText2') }}
			</b-card-text>
			<b-button @click="openPrintView">
				{{ $t('paiduser.termsOfService.printView') }}
			</b-button>
		</b-modal>
	</div>
</template>


<script>
import { required, minLength, email, maxLength, requiredIf } from 'vuelidate/lib/validators';
import * as FormHelper from '@/util/form-helper.js';
import { checkBioC } from '@/repository/bml.js';
import { getDefaultSelectListFromArray } from '@/util/select-mapping';
import { alphanumericSort } from '@/util/sort';
import MultiSelect from '@/components/field/MultiSelect';
import { fieldToLocale } from '@/util/locale';
import EventHub from '@/util/eventbus.js';

let PAID_USERS = [ ];

export default {
	name: 'UserDataForm',

	components: {
		MultiSelect
	},

	props: {
		userData: {
			type: Object,
			default: () => {
				return {
					mainAddress: {
						name: '',
						street: '',
						citycode: '',
						city: '',
						countrycode: '',
						email: ''
					},
					invoiceAddress: {
						name: '',
						street: '',
						citycode: '',
						city: '',
						countrycode: '',
						email: ''
					},
					controlNumber: '',
					controlBody: null,
					contactPersonFirstname: '',
					contactPersonLastname: '',
					contactPhone: '',
					taxId: '',
					commentUser: '',
					tosAccepted: false,
					associations: null
				};
			}
		},
		formType: {
			type: String,
			default: ''
		},
		submitOnCooldown: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			form: {},
			useAdditionalInvoiceAddress: false,
			showContactPerson: false,
			biocCache: new Map(),
			isControlNumberValid: false,
			statusMessage: '',
			proceedWithout: false,
			isWorking: false,
			mainCountry: 'DE'
		};
	},

	computed: {
		displayUserdataform() {
			let country = this.mainCountry;
			if (country === 'DE') {
				return this.form.controlNumber && this.form.controlBody && this.isControlNumberValid ||
					this.proceedWithout || this.isEditData;
			} else {
				return true;
			}
		},
		controlBodyOptions() {
			let options = this.$store.getters['selectoptions/byTypeSorted']('controlbody');
			options = options.filter(option => option.data.title.de_DE && option.data.title.de_DE.startsWith('DE-'));
			return getDefaultSelectListFromArray(options, 'title', true)
				.sort(alphanumericSort);
		},
		canCheckBioc() {
			return this.form.controlNumber ? this.form.controlBody && this.form.controlBody.value && this.form.controlNumber && !this.$v.form.controlNumber.$error : false;
		},
		isEditData() {
			return this.formType === 'editData';
		},
		countryCodes() {
		let countries = this.$store.getters['country/allSorted'].filter(country => country.data.countrycode && country.data.countrycode.length === 2);
		return countries.map(country => {
				return {
					value: country.data.countrycode,
					text: fieldToLocale(country.data.title)
				}
			});
		},
		selectedLanguage() {
			return this.$store.state.search.selectedLocale;
		},
		associationNames() {
			let names = [];
			if (this.form.associations && this.form.associations.length) {
				for (let association of this.form.associations) {
					names.push(this.$t(`paiduser.biostandards.${association}`));
				}
			}
			return names.join(', ');
		},
		isPaidUser() {
			return PAID_USERS.includes(this.form.role);
		},
		paidClass() {
			return `font-weight-normal ${ this.isPaidUser ? 'requiredStar' : '' }`;
		},
		mainCountryOptions() {
			let germany = this.countryCodes.find(code => code.value === 'DE');
			let other = { text: this.$t('paiduser.registration.otherCountry'), value: null };
			return [ germany, other ];
		}
	},

	watch: {
		'form.countryCode'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			if (newVal !== 'DE') {
				this.form.controlBody = null;
				this.form.controlNumber = '';
			}
		},
		'form.controlNumber'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.resetStatus();
		},
		'form.controlBody'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			if (this.form.countryCode === 'DE') {
				this.proceedWithout = false;
				if (!newVal) {
					this.resetStatus();
				}
			}
		},
		useAdditionalInvoiceAddress(newVal) {
			if (!newVal) {
				this.$set(this.form, 'invoiceAddress', null);
			} else {
				this.initiInvoiceAddress();
			}
		}
	},

	async created() {
		this.$set(this, 'form', JSON.parse(JSON.stringify(this.userData)));
		await this.$store.dispatch('selectoptions/loadAll', { ifEmpty: true });
		await this.$store.dispatch('country/loadAll', { ifEmpty: true });
		if (!this.isEditData) this.initiInvoiceAddress();
		this.useAdditionalInvoiceAddress = !!(this.form.invoiceAddress && this.form.invoiceAddress.email) || this.isPaidUser;
		this.showContactPerson = this.isPaidUser;
		if (!this.form.role) {
			this.form.role = 'other';
		}
		if (!this.isEditData) {
			this.mainCountry = 'DE';
			if (!this.form.mainAddress.countrycode) {
				let countryCode = this.selectedLanguage ? this.selectedLanguage.substring(3, 5) : 'DE';
				let entry = this.countryCodes.find(entry => entry.value === countryCode);
				this.form.mainAddress.countrycode = entry && entry.value ? entry.value : null;
			}
		}
		if (!this.form.controlBody) {
			this.$set(this.form, 'controlBody', { value: null, text: '-' });
		}
		let option = this.controlBodyOptions.find(option => option.value === this.form.controlBody);
		this.form.controlBody = option ? option : { value: null, text: '-' };
		this.statusMessage = '';
	},

	methods: {
		resetStatus() {
			if (!this.isEditData) {
				this.form.associations = null;
			}
			this.statusMessage = this.$t('paiduser.registration.status.unchecked');
		},
		getBiocCacheData(number) {
			if (this.biocCache.has(number)) {
				return this.biocCache.get(number);
			}
			return null;
		},
		copyUserDataFromBioc(data) {
			let producer = data.producer;
			if (producer.city) this.form.mainAddress.city = producer.city;
			if (producer.citycode) this.form.mainAddress.citycode = producer.citycode;
			if (producer.name) this.form.mainAddress.name = producer.name;
			if (data.certficates && data.certficates.length) {
				let localCertificate = data.certficates.find(cert => cert.country === this.form.mainAddress.countrycode);
				if (localCertificate && localCertificate.address) {
					this.form.mainAddress.street = localCertificate.address;
				}
			}
		},
		setUserStatus() {
			let data = this.getBiocCacheData(this.form.controlNumber);
			let newRoleAssigned = false;
			if (data) {
				if (data.certficates && data.certficates.length) {
					let nonEuCertificates = data.certficates.filter(entry => entry.bio_standard && entry.bio_standard !== 'EU');
					if (nonEuCertificates.length) {
						this.statusMessage = this.$t('paiduser.registration.status.associationMember');
						this.form.role = 'member';
						this.form.associations = nonEuCertificates.map(entry => entry.bio_standard);
					} else {
						this.statusMessage = this.$t('paiduser.registration.status.paidSolo');
						this.form.role = 'solo';
					}
					newRoleAssigned = true;
				} else {
					this.statusMessage = this.$t('paiduser.registration.status.notFound');
				}
				if (data.producer) {
					this.copyUserDataFromBioc(data);
				}
			}
			this.isControlNumberValid = this.isValidControlNumber(this.form.controlNumber);
			if (!newRoleAssigned) this.form.role = 'other';
		},
		async checkBiocData() {
			if (!this.form.controlNumber) return false;
			let number = this.form.controlNumber;
			let data = null;
			if (this.getBiocCacheData(number)) {
				data = this.getBiocCacheData(number);
			} else {
				try {
					this.isWorking = true;
					data = await checkBioC(number);
					if (data && data.error && data.error.includes('unknown operator id')) {
						this.statusMessage = this.$t('paiduser.registration.status.notFound');
					}
				} finally {
					this.isWorking = false;
				}
				this.biocCache.set(number, data);
			}
			this.setUserStatus();
			return data;
		},
		isValidControlNumber(controlNumber) {
			let data = this.biocCache.get(controlNumber);
			return data && data.producer && data.certficates && data.certficates.length;
		},
		submit() {
			this.$v.$touch();
			if (this.$v.$anyError) {
				FormHelper.emitFormError(this.$v);
				EventHub.$emit('warning', this.$t('paiduser.messages.operationFailed'), this.$t('paiduser.messages.fillInAllFields'));
				FormHelper.scrollToFirstError();
				return;
			}
			let userDataCopy = JSON.parse(JSON.stringify(this.form));
			if (!this.useAdditionalInvoiceAddress) {
				delete userDataCopy.invoiceAddress;
			}
			if (userDataCopy.mainAddress.countrycode !== 'DE') {
				delete userDataCopy.controlNumber;
				delete userDataCopy.controlBody;
			}
			if (userDataCopy.controlBody && userDataCopy.controlBody.value) {
				userDataCopy.controlBody = userDataCopy.controlBody.value;
			} else {
				delete userDataCopy.controlBody;
			}
			if (!userDataCopy.associations || !userDataCopy.associations.length) {
				delete userDataCopy.associations;
			}
			this.$emit('save', userDataCopy);
		},
		initiInvoiceAddress() {
			if (!this.form.invoiceAddress) {
				this.$set(this.form, 'invoiceAddress', {
					name: '',
					street: '',
					citycode: '',
					city: '',
					countrycode: '',
					email: '',
				});
			}
		},
		toggleProceedWithout() {
			this.proceedWithout = !this.proceedWithout;
		},
		showAgbModal() {
			this.$refs.agbModal.show();
		},
		openPrintView() {
			window.print();
		}
	},
	validations: {
		form: {
			mainAddress: {
				name: {
					required,
					minLength: minLength(1),
					maxLength: maxLength(200)
				},
				street: {
					required: requiredIf(function() {
						return this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(200)
				},
				citycode: {
					required: requiredIf(function() {
						return this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(50)
				},
				city: {
					required: requiredIf(function() {
						return this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(120)
				},
				countrycode: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(2)
				},
				email: {
					required,
					email
				}
			},
			invoiceAddress: {
				name: {
					required: requiredIf(function() {
						return this.useAdditionalInvoiceAddress || this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(200)
				},
				street: {
					required: requiredIf(function() {
						return this.useAdditionalInvoiceAddress || this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(200)
				},
				citycode: {
					required: requiredIf(function() {
						return this.useAdditionalInvoiceAddress || this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(50)
				},
				city: {
					required: requiredIf(function() {
						return this.useAdditionalInvoiceAddress || this.isPaidUser;
					}),
					minLength: minLength(1),
					maxLength: maxLength(120)
				},
				countrycode: {
					required: requiredIf(function() {
						return this.useAdditionalInvoiceAddress || this.isPaidUser;
					}),
					minLength: minLength(2),
					maxLength: maxLength(2)
				},
				email: {
					required: requiredIf(function() {
						return this.useAdditionalInvoiceAddress || this.isPaidUser;
					}),
					email
				}
			},
			controlNumber: {
				maxLength: maxLength(50),
				minLength: minLength(5),
				required: requiredIf(function() {
					return this.form.controlBody && this.form.controlBody.value;
				})
			},
			controlBody: {
			},
			contactPersonFirstname: {
				required: requiredIf(function() {
					return this.isPaidUser;
				}),
				minLength: minLength(2),
				maxLength: maxLength(100)
			},
			contactPersonLastname: {
				required: requiredIf(function() {
					return this.isPaidUser;
				}),
				minLength: minLength(2),
				maxLength: maxLength(100)
			},
			contactPhone: {
				required: requiredIf(function() {
					return this.isPaidUser;
				}),
				minLength: minLength(2),
				maxLength: maxLength(50)
			},
			taxId: {
				maxLength: maxLength(50)
			},
			commentUser: {
				maxLength: maxLength(1000)
			},
			tosAccepted: {
				required: requiredIf(function() {
					return this.formType === 'registration';
				}),
				mustBeChecked(v) {
					return !!v;
				}
			}
		}
	}
};
</script>
