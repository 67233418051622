<template>
	<b-card>
		<h1>{{ $t('paiduser.registration.header') }}</h1>
		<user-data-form
			@save="save"
			form-type="registration"
			:user-data="userData"
			:submit-on-cooldown="submitOnCooldown"
			v-if="isLoggedIn && init"
		/>
		<div v-if="!isLoggedIn">
			<p>
				{{ $t('menu.login.notLoggedIn') }}
			</p>
			<b-button
				@click="gotoLogin"
				variant="primary"
			>
				{{ $t('menu.login.loginWithSso') }}
			</b-button>
		</div>
	</b-card>
</template>


<script>
import EventBus from '@/util/eventbus';
import UserDataForm from '@/components/UserDataForm';
import { doesPaidUserExist } from '@/repository/bml';

const SUBMIT_COOLDOWN = 5 * 1000;

export default {

	name: 'RegistrationForms',

	components: {
		UserDataForm
	},

	data() {
		return {
			init: false,
			submitOnCooldown: false,
			userData: {
				mainAddress: {
					name: '',
					street: '',
					citycode: '',
					city: '',
					countrycode: '',
					email: ''
				},
				controlNumber: '',
				controlBody: '',
				contactPersonFirstname: '',
				contactPersonLastname: '',
				contactPhone: '',
				taxId: '',
				commentUser: '',
				tosAccepted: false,
				role: 'other',
				associations: null
			}
		};
	},

	computed: {
		isLoggedIn() {
			return this.$store.getters['auth/token/isLoggedIn'];
		}
	},

	async created() {
		await this.$store.dispatch('auth/token/initApp');
		let sso = this.$store.state.auth.token.ssoParsed;
			if (!sso) return;
			if (sso.email) this.userData.mainAddress.email = sso.email;
			if (sso.name) this.userData.mainAddress.name = sso.name;
			if (sso.street) this.userData.mainAddress.street = sso.street;
			if (sso.citycode) this.userData.mainAddress.citycode = sso.citycode;
			if (sso.city) this.userData.mainAddress.city = sso.city;
		this.init = true;
	},

	methods: {
		gotoLogin() {
			this.$store.commit('auth/token/setNoRedirectAfterAccountSwitch', true);
			this.$store.dispatch('auth/token/authenticate');
		},
		async save(userData) {
			let result;
			let role = userData.role;
			delete userData.role;
			this.submitOnCooldown = true;
			setTimeout(() => {
				this.submitOnCooldown = false;
				}, SUBMIT_COOLDOWN);
			try {
				let userExists = await doesPaidUserExist({ name: userData.mainAddress.name, countrycode: userData.mainAddress.countrycode, email: userData.mainAddress.email });
				if (userExists) {
					this.submitOnCooldown = false;
					EventBus.$emit('warning', this.$t('paiduser.messages.userExists'), this.$t('paiduser.messages.userExists'));
					return;
				}
				result = await this.$store.dispatch('auth/user/registerWithoutInvite', {
					userData,
					registrationName: `paid_${role}`
				});
			} catch(e) {
				this.submitOnCooldown = false;
				throw e;
			}
			if (result) EventBus.$emit('success', this.$t('paiduser.messages.operationSuccessful'), this.$t('paiduser.messages.registrationSuccessful'));
		}
	}

};
</script>
