import DisplayModule from '@fibl/bml-display';
import { getTotalSelection } from '@/util/tree';
import { DisplayOptionsProvider } from '@/services/DisplayOptionsProvider';
import { flatten, unflatten } from 'flat';
const Display = DisplayModule.display.default;

const state = {
	productId: null,
	listId: null,
	listData: null,
	baseListData: null,
	categoryPath: '_category',
	usedCompanies: [],
	display: null
};

const listOfPathsForDeepLinking = [
	'_components',
	'_risklabels',
	'_approval'
];

export const doNotMergePaths = [
	'_documents',
	'_checklist',
	'_approval',
	'_notes',
	'_companies',
	'_display',
	'_responsible',
	'_hobbygarden',
	'_hobbygardenTree',
	'_linked',
	'activatedOn',
	'activatedBy',
	'deactivatedOn',
	'activatedBy'
];

function toIndex(c, e) {
	c[e._id] = e;
	return c;
}

function mergeComponentArray(a, b) {
	if (!b) return [];
	if (!a) return b;
	let baseIdx = a.reduce(toIndex, {});
	let listIdx = b.reduce(toIndex, {});
	let filteredBase = Object.fromEntries(Object.entries(baseIdx).filter(([k]) => (k in listIdx)));
	let flatBase = flatten(filteredBase);
	let flatList = flatten(listIdx);
	let flatCombined = { ...flatBase, ...flatList };
	let rebuilt = unflatten(flatCombined);
	return Object.values(rebuilt);
}

function componentMerge(baseList, listComp) {
	if (!baseList) return listComp;
	let base = mergeComponentArray(baseList._components?.base, listComp.base);
	let additional = mergeComponentArray(baseList._components?.additional, listComp.additional);
	return { base, additional };
}

function mergeListAndBase(listData, baseData) {
	const merged = {};
	Object.keys(listData).forEach(key => {
		let v = JSON.parse(JSON.stringify(listData[key]));
		if (key === '_components') {
			merged[key] = componentMerge(baseData, v);
		} else if (listOfPathsForDeepLinking.includes(key) && baseData && baseData[key]) {
			let flatBase = flatten(baseData[key]);
			let flatList = flatten(v);
			let flatCombined = { ...flatBase, ...flatList };
			merged[key] = unflatten(flatCombined, { overwrite: true });
		} else {
			merged[key] = v;
		}
	});
	if (baseData) {
		Object.keys(baseData).forEach(key => {
			if (key in merged || doNotMergePaths.includes(key)) return;
			let v = JSON.parse(JSON.stringify(baseData[key]));
			merged[key] = v;
		});
	}
	return merged;
}

function deepGet(v, parts) {
	for (let i=0, ii=parts.length; i<ii; i++) {
		let p = parts[i];

		if (p in v) {
			v = v[p];
		} else {
			return null;
		}
	}
	if (typeof v !== 'undefined' && v !== null) {
		return v;
	} else {
		return null;
	}
}

const getters = {
	/**
	 * Gets an single value by passing a path.
	 */
	getValue: (state) => (obj, path) => {
		let parts = path.split('.');
		return deepGet(state[obj], parts);
	},

	/**
	 * Gets all displayed fields.
	 */
	getDisplayFields: (state) => () => {
		return state.display.getDisplayFields();
	},

	/**
	 * Returns an array with all field data.
	 */
	getFieldData: (state, getters, rootState, rootGetters) => {
		return rootGetters['field/fields'];
	},

	/**
	 * Returns an array with all permit data.
	 */
	getPermitData: (state, getters, rootState, rootGetters) => {
		return rootGetters['permit/all'];
	},

	/**
	 * Returns an array with all component data.
	 */
	getComponentData: (state, getters, rootState, rootGetters) => {
		return rootGetters['component/all'];
	},

	/**
	 * Returns an array with all biostandard data.
	 */

	getBiostandardData: (state, getters, rootState, rootGetters) => {
		return rootGetters['biostandard/all'];
	},

	/**
	 * Returns an array with all approval data.
	 */

	getApprovalData: (state, getters, rootState, rootGetters) => {
		if (!state.listData && !state.baseListData) {
			return [];
		}

		return rootGetters['approvals/getApprovalDataWithParent'](state.listData, state.baseListData, state.listId);
	},

	getCategoryData: (state, getters, rootState, rootGetters) => {
		return rootGetters['category/all'];
	},

	getCompanyBaseData: (state) => {
		return state.usedCompanies;
	},

	getCompanyData: (state, getters) => {
		return getters.getValue('listData', '_companies');
	},

	/**
	 * Returns the actual main category of the selected category in the category tree.
	 */
	getSelectedMainCategory: (state, getters, rootState, rootGetters) => {
		let v = getters.getValue('listData', '_categoryTree');
		if (!v) {
			v = getters.getValue('baseListData', '_categoryTree');
			if (!v) {
				return null;
			}
		}

		const totalEntries = getTotalSelection('category', JSON.parse(JSON.stringify(v)));
		for (const id of totalEntries) {
			let category = rootGetters['category/byId'](id);
			if (!category.data.parent && category.data.mainCategoryKey) {
				return category;
			}
		}
		return null;
	},

	getPrintLanguage: (state, getters, rootState) => () => {
		return rootState.search.selectedLocale;
	},

	getListData: (state) => () => {
		let workingCopy = JSON.parse(JSON.stringify(mergeListAndBase(state.listData, state.baseListData)));

		if (!workingCopy._components) {
			workingCopy._components = {};
		}

		if (!workingCopy._components.base) {
			workingCopy._components.base = [];
		}

		// merge additional components
		if (workingCopy._components.additional) {
			for (let v of Object.values(workingCopy._components.additional)) {
				workingCopy._components.base.push(v);
			}
		}

		return workingCopy;
	},

	/**
	 * Get default display value for field (shown above the field)
	 */
	getDisplayValue: (state) => (language, fieldKey) => {
		return state.display.getDisplayValue(language, fieldKey);
	},

	/**
	 * Creates the HTML output of the template
	 */
	getPrintDataHTML: (state, getters, rootState) => () => {
		if (!state.display) {
			return '';
		}

		return state.display.getPrintDataHTML([ rootState.search.selectedLocale ]);
	}
};

const actions = {
	async loadNecessaryCompanies({ getters, dispatch, commit }) {
		const listData = getters.getListData();

		if (!listData._companies) {
			return;
		}

		const uniqueCompanyIds = [...new Set(listData._companies.map(f => f.company))];
		if (uniqueCompanyIds.length === 0) {
			return;
		}

		const list = await dispatch('companylist/loadByIds', uniqueCompanyIds, { root: true });
		commit('setUsedCompanies', list);
	},

	/**
	 * Set default state.
	 */
	async init({ state, commit, dispatch, getters, rootGetters }, { productDataObj, productId, listId, standard, categories, components, historyDate }) {
		await DisplayOptionsProvider.initialize({ categoryIds: categories, componentIds: components });

		const selectOptions = rootGetters['selectoptions/all'];
		commit('setProductId', productId);

		let productData;

		if (productDataObj) {
			productData = JSON.parse(JSON.stringify(productDataObj));
		} else {
			productData = await dispatch('search/loadSingleProductById', { productId: state.productId, historyDate });
		}

		if (!productData) {
			return;
		}

		commit('setListId', listId);
		commit('setListData', productData.data.listData[listId]);

		const baseListId = rootGetters['list/baseListId'];
		if (listId !== baseListId) {
			commit('setBaseListData', productData.data.listData[baseListId]);
		}

		await dispatch('loadNecessaryCompanies');

		commit('init', {
			productData: getters.getListData(),
			selectOptions,
			mainCategory: getters.getSelectedMainCategory,
			language: getters.getPrintLanguage(),
			standard,
			fieldData: getters.getFieldData,
			permits: getters.getPermitData,
			components: getters.getComponentData,
			biostandards: getters.getBiostandardData,
			approvals: getters.getApprovalData,
			categories: getters.getCategoryData,
			fallbackLanguage: rootGetters['search/getFallbackLocale'],
			companyData: getters.getCompanyData,
			companyBase: getters.getCompanyBaseData,
			list: rootGetters['list/byId'](listId)
		});
		dispatch('updateProductData');
	},

	updateProductData({ state, commit, getters}) {
		if (!state.display) {
			return;
		}
		commit('updateProductData', {
			productData: getters.getListData(),
			language: getters.getPrintLanguage(),
			standard: null,
			productIds: [state.productId]
		});
	},

	async changeList({ commit, dispatch, rootGetters }, { productData, listId }) {
		commit('setListId', listId);
		commit('updateList', rootGetters['list/byId'](listId));
		commit('setListData', productData.data.listData[listId]);
		await dispatch('loadNecessaryCompanies');
		dispatch('updateProductData');
	}
};

const mutations = {
	init(state, { selectOptions, mainCategory, language, standard, fieldData, permits, components, biostandards,
		approvals, categories, fallbackLanguage, companyData, companyBase, list }) {
		state.display = new Display({
			selectOptions,
			mainCategory,
			language,
			standard,
			fieldData,
			permits,
			components,
			biostandards,
			approvals,
			categories,
			fallbackLanguage,
			companyData,
			companyBase,
			list
		});
	},

	updateProductData(state, { productData, language, standard, productIds }) {
		state.display.updateProductData(productData);
		state.display.updateLanguage(language);
		state.display.updateStandard(standard);
		state.display.updateProductIds(productIds);
	},

	updateList(state, list) {
		state.display.updateList(list);
	},

	setProductId(state, productId) {
		state.productId = productId;
	},

	setListId(state, listId) {
		state.listId = listId;
	},

	setListData(state, listData) {
		state.listData = listData;
	},

	setBaseListData(state, listData) {
		state.baseListData = listData;
	},

	setUsedCompanies(state, usedCompanies) {
		state.usedCompanies = usedCompanies;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
