<template>
	<div class="search ml-3 mt-3 mr-3">
		<template v-if="isLoggedIn">
			<template v-if="accountName">
				<edit-user-data :user-data="accountDetails.userData" form-type="editUserData" />
			</template>
			<template v-else>
				<b-container>
					<h3>{{ $t('paiduser.userCenter.notRegistered') }}</h3>
					{{ $t('paiduser.userCenter.notRegistered') }}
				</b-container>
				<b-container>
					<b-button variant="primary"
						@click="goto('Registration')"
					>
						{{ $t('paiduser.registration.header') }}
					</b-button>
				</b-container>
				<b-container>
					<b-button variant="primary"
						@click="goto('RegistrationAso')"
					>
						{{ $t('paiduser.userCenter.aso') }}
					</b-button>
				</b-container>
			</template>
		</template>
		<template v-else>
			<b-button variant="primary" @click="gotoLogin">
				{{ $t('menu.login.loginWithSso') }}
			</b-button>
		</template>
	</div>
</template>

<script>
import EditUserData from '@/components/EditUserData.vue';

export default {
	name: 'UserCenter',

	components: {
		EditUserData
	},

	data() {
		return {
			loading: false,
			userData: {
				type: Object,
				default: () => {
					return {
						mainAddress: {
							name: '',
							street: '',
							citycode: '',
							city: '',
							countrycode: '',
							email: ''
						},
						invoiceAddress: {
							name: '',
							street: '',
							citycode: '',
							city: '',
							countrycode: '',
							email: '',
						},
						controlNumber: '',
						contactPersonFirstname: '',
						contactPersonLastname: '',
						contactPhone: '',
						taxId: '',
						commentUser: '',
						tosAccepted: false
					};
				}
			}
		};
	},

	computed: {
		isLoggedIn() {
			return this.$store.getters['auth/token/isLoggedIn'];
		},
		accountId() {
			return this.$store.getters['auth/token/account'];
		},
		accountName() {
			return this.$store.getters['auth/user/accountName'];
		},
		accountDetails() {
			return this.$store.getters['auth/user/accountDetails'] || JSON.parse(JSON.stringify(this.userData));
		}
	},

	methods: {
		gotoLogin() {
			this.$store.dispatch('auth/token/authenticate');
		},
		goto(route) {
			this.$router.push({
				name: route
			});
		},
	}
};
</script>
