import { getEntityclassByExternalId } from '@/repository/system.js';

const ENTITY_CLASS_ID = 'products';

const state = {
	entityClassId: ''
};

const actions = {
	async _setEntityClassId({ state, commit }) {
		if (!state.entityClassId) {
			const entityClass = await getEntityclassByExternalId(ENTITY_CLASS_ID);
			commit('setEcId', entityClass.id);
		}
	}
};

const mutations = {
	setEcId(state, ecId) {
		state.entityClassId = ecId;
	}
};

export default {
	namespaced: true,
	state,
	actions,
	mutations
};
