import Store from '@/store/index';
import { fieldToLocale, NO_RESULT } from './locale';

export function toListDisplay(listId) {
	if (!listId) {
		return NO_RESULT;
	}

	const listObject = Store.getters['list/byIdUnfiltered'](listId);

	if (!listObject) {
		return NO_RESULT;
	}

	return fieldToLocale(listObject.data.title);
}

function genericDisplay(baseData, entry, titleField) {
	if (!baseData || baseData.length === 0) return '';
	const actualEntry = baseData.find(x => x.id === entry);
	if (!actualEntry) return '';

	return fieldToLocale(actualEntry.data[titleField || 'title']);
}

export function categoryDisplay(category) {
	return genericDisplay(Store.state.category.entries, category);
}

export function componentDisplay(component) {
	return genericDisplay(Store.state.component.components, component);
}

export function hobbygardenDisplay(hobbygarden) {
	return genericDisplay(Store.state.hobbygarden.entries, hobbygarden);
}

export function treeDisplay(type, value) {
	if (type === 'category') {
		return categoryDisplay(value);
	} else if (type === 'component') {
		return componentDisplay(value);
	} else if (type === 'hobbygarden') {
		return hobbygardenDisplay(value);
	}

	return value;
}

export function biostandardDisplay(biostandardId) {
	let name = genericDisplay(Store.state.biostandard.biostandards, biostandardId, 'publicName');
	if (name === NO_RESULT) {
		// fallback to default name
		name = genericDisplay(Store.state.biostandard.biostandards, biostandardId, 'name');
	}

	return name;
}

export function selectOptionDisplay(id) {
	return genericDisplay(Store.state.selectoptions.options, id);
}

export function countryDisplay(countryId) {
	return genericDisplay(Store.state.country.countries, countryId);
}

export function mapObjectToSelectOption(field) {
	return (obj) => {
		return {
			value: obj.id,
			text: fieldToLocale(obj.data[field])
		};
	};
}

export function getSelectListFromArray(array, titleField, useEmptyValue, emptyKey, emptyText) {
	let data = array.map(mapObjectToSelectOption(titleField));

	if (useEmptyValue) {
		data.unshift({
			value: emptyKey,
			text: emptyText
		});
	}

	return data;
}

export function getDefaultSelectListFromArray(array, titleField, useEmptyValue) {
	return getSelectListFromArray(array, titleField, useEmptyValue, null, '-');
}
