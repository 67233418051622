import { getEntityclassByExternalId } from '@/repository/system.js';
import { search , getSingle } from '@/repository/system';

const state = {

	permits: [],
	entityclassId: ''

};

const getters = {

	all: (state) => {
		return state.permits;
	},

	byId: (state) => (id) => {
		return state.permits.find(r => r.id === id);
	},


};

const actions = {

	async ensureEntityclassLoaded({ state, commit }) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('permit');
			commit('setEcId', { id: ec.id });
		}
	},

	async loadAll({ state, commit, dispatch }, { ifEmpty }) {
		if (ifEmpty && state.permits.length > 0) return;
		await dispatch('ensureEntityclassLoaded');
		let list = await search(state.entityclassId);
		commit('setPermits', list);
	},

	async loadById({ state, commit }, { id }) {
		if (!id) return;
		let permit = state.permits.find(permit => permit.id === id);
		if (permit) return;
		let entry = await getSingle(id);
		commit('addPermit', entry);
	},

};

const mutations = {

	setPermits(state, entries) {
		state.permits = entries;
	},

	setEcId(state, { id }) {
		state.entityclassId = id;
	},

	addPermit(state, entry) {
		if (!entry) return;
		state.permits.push(entry);
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
