import Store from '@/store/index';

export const NO_RESULT = '-';

export function fieldToLocale(obj) {
	if (!obj) {
		return NO_RESULT;
	}

	const currentLocale = Store.getters['search/getLocale'];

	if (typeof obj === 'string') {
		return obj;
	}

	if (typeof obj === 'undefined') {
		return NO_RESULT;
	}

	if (obj[currentLocale]) {
		return obj[currentLocale];
	}

	if (obj[Store.getters['search/getFallbackLocale']]) {
		return obj[Store.getters['search/getFallbackLocale']];
	}

	const keys = Object.keys(obj);
	if (keys.length > 0) {
		return obj[keys[0]];
	}

	return NO_RESULT;
}

export function toSortable(str) {
	if (typeof str !== 'string') return '';
	return str.toLowerCase()
		.replace('ö', 'o')
		.replace('ä', 'a')
		.replace('ü', 'u');
}

export function extractField(obj, field) {
	const opt = obj;

	if (!opt) {
		return NO_RESULT;
	}

	if (typeof obj === 'string') {
		return obj;
	}

	if (field in obj) {
		return obj[field];
	}

	return NO_RESULT;
}

function _extractDataFromList(data, listId, field) {
	if (listId in data) {
		const base = data[listId];
		if (field in base) {
			if (field === '_categoryTree') {
				// console.log('extract', listId, field, base[field]);
			}
			return base[field];
		}
	}
	return null;
}

export function extractDataFromList(baseData, field, selectedListId, baseListId) {
	if (!baseData) {
		return '';
	}

	let result = _extractDataFromList(baseData, selectedListId, field);

	if (!result && baseListId) {
		result = _extractDataFromList(baseData, baseListId, field);
	}

	return result || '';
}

export function unwrapValue(obj) {
	// allowed attributes for unwrapping
	const allowedAttributes = ['textValue', 'numberValue', 'booleanValue', 'selectValue', 'multiselectValue', 'dateValue'];
	if (typeof obj === 'object') {
		for (const attribute of allowedAttributes) {
			if (attribute in obj) {
				return obj[attribute];
			}
		}
	}
	return null;
}
