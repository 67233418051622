<template>
	<div>
		<span v-if="item && item.name">{{ item.name }}</span>
		<span v-else>&nbsp;</span>
	</div>
</template>

<script>
export default {
	props: {
		item: { required: true, type: Object }
	}
};
</script>
