import { toDateFormat } from '@/plugins/filters';

export function calculateRelevantHistory(product, allStandards, anyStandard) {
	// console.log('calculateDeactivationDate', product, allStandards, anyStandard);
	const allStandardIds = allStandards ? allStandards.map(e => e.id) : [];
	const anyStandardIds = anyStandard ? anyStandard.map(e => e.id) : [];
	const combinedStandardIds = allStandardIds.concat(anyStandardIds);
	const relevantHistory = (product.data.approvalHistoryLatest || product.data.approvalHistory)
		.filter(e => combinedStandardIds.includes(e.biostandardId))
		.sort((a, b) => (a.begin > b.begin ? 1 : -1));

	const active = new Set();
	const allValid = () => {
		if (allStandardIds.length) {
			for (let id of allStandardIds) {
				if (!active.has(id)) return false;
			}
			return true;
		}
		if (anyStandardIds.length) {
			for (let id of anyStandardIds) {
				if (active.has(id)) return true;
			}
			return false;
		}
		return true;
	}
	const timelineDatesSet = new Set();
	for (let entry of relevantHistory) {
		timelineDatesSet.add(entry.begin);
		if (entry.end) timelineDatesSet.add(entry.end);
	}
	const now = new Date().toJSON().substring(0, 10);
	const timelineDates = Array.from(timelineDatesSet).filter(e => e < now).sort((a, b) => (a > b ? 1 : -1));
	const result = [];
	let lastEntry;
	let lastState = false;
	for (let date of timelineDates) {
		for (let entry of relevantHistory) {
			if (entry.begin === date) {
				active.add(entry.biostandardId);
			}
			if (entry.end === date) {
				active.delete(entry.biostandardId);
			}
		}
		const currentState = allValid();
		if (currentState !== lastState) {
			if (lastEntry) lastEntry.end = (date);
			lastEntry = {
				begin: (date),
				state: currentState,
				end: ''
			}
			result.push(lastEntry)
		}
		lastState = currentState;
		// console.log({ date, active, allValid: allValid(), lastValidDate, lastInvalidDate });
	}
	// console.log('history', result);
	return result;
}

export function getHistoricName(product, listId, historyDate, lang) {
	// console.log('getHIstoricName', { product, listId, historyDate, lang })
	let listData = product.data.listData[listId];
	if (!listData._nameHistory?.length) {
		return null;
	}
	let last = '';
	for (let entry of listData._nameHistory) {
		if (new Date(entry.from) > historyDate) break;
		if (entry.name[lang]) last = entry.name[lang];
	}
	return last;
}

// module.exports = {
// 	calculateDeactivationDate
// };
