<template>
	<div id="BmlProductDetail"
		class="text-left"
	>
		<b-button @click.prevent="backToSearchResults" class="mb-2">
			{{ $t('singleResult.back') }}
		</b-button>
		<b-button
			:disabled="pdfWorking"
			@click.prevent="getPdf"
			class="mb-2 ml-2"
			variant="primary"
			v-show="canShowPdf"
		>
			<b-spinner small v-if="pdfWorking" />
			{{ $t('singleResult.pdf') }}
		</b-button>
		<b-alert
			:show="error.length > 0"
			variant="danger"
			style="white-space: pre-line;"
		>
			{{ error }}
		</b-alert>
		<template v-if="productData">
			<default-search-result v-if="!isHobbyGardenList"
				:product-data="productData"
				:distributor="distributor"
				:main-address="mainAddress"
				:all-categories="allCategories"
				:all-components="allComponents"
			/>
			<hobby-search-result v-else
				:product-data="productData"
				:distributor="distributor"
				:main-address="mainAddress"
			/>
		</template>
	</div>
</template>

<script>
import DefaultSearchResult from '@/components/SearchResult/DefaultSearchResult';
import HobbySearchResult from '@/components/SearchResult/HobbySearchResult';
import { extractDataFromList, NO_RESULT} from '@/util/locale';

export default {
	name: 'SingleSearchResult',

	components: {
		DefaultSearchResult,
		HobbySearchResult
	},

	data() {
		return {
			error: '',
			pdfWorking: false,
			productData: null,
			distributor: null,
			mainAddress: null
		};
	},

	computed: {
		isHobbyGardenList() {
			const list = this.$store.getters['list/byId'](this.listId);
			if (list) {
				return list.data.targetAudience === 'hobby';
			}

			return false;
		},
		productId() {
			return this.$route.params.product_id;
		},
		listId() {
			return this.$route.params.list_id;
		},
		historyDate() {
			return this.$route.params.date;
		},
		locale() {
			let lc = this.$route.params.locale;
			let end = lc.indexOf('&');	//can happen bc of keycloak redirect
			if (end < 0) return lc;
			return lc.substring(0, end);
		},
		baseListId() {
			return this.$store.getters['list/baseListId'];
		},
		listData() {
			if (!this.productData) {
				return null;
			}
			return this.productData.data.listData;
		},
		selectedListData() {
			const listData = this.listData;
			if (listData) {
				return listData[this.listId];
			}
			return null;
		},
		indexData() {
			if (!this.productData) {
				return null;
			}
			const productDataUnwrapped = this.productData.data;
			if (productDataUnwrapped.indexData) {
				return productDataUnwrapped.indexData;
			}
			return null;
		},
		baseIndexData() {
			const indexData = this.indexData;
			if (indexData) {
				return indexData[this.baseListId];
			}
			return null;
		},
		selectedIndexData() {
			const indexData = this.indexData;
			if (indexData) {
				return indexData[this.listId];
			}
			return null;
		},
		canShowPdf() {
			let id = this.listId;
			let list = this.$store.getters['list/byId'](id);
			if (!list) return false;
			return list.data.targetAudience === 'commercial' && !this.historyDate;
		},
		allCategories() {
			const categories = this.extractDataFromList('_categoryTree');
			if (!Array.isArray(categories)) {
				return [];
			}
			return categories;
		},
		allComponents() {
			let components = this.extractDataFromList('_components');
			if (!components) return [];
			let allComponents = [];
			let allComponentCategories = [];

			if (Array.isArray(components.base) && components.base.length) {
				allComponents = allComponents.concat(components.base);
			}
			if (Array.isArray(components.additional) && components.additional.length) {
				allComponents = allComponents.concat(components.additional);
			}
			for (let component of allComponents) {
				if (component._category && component._category.length) {
					allComponentCategories = allComponentCategories.concat(component._category);
				}
			}

			return allComponentCategories;
		}
	},

	async created() {
		if (!this.productId || !this.listId) {
			this.backToSearchResults();
			return;
		}
		if (this.locale) {
			this.$store.commit('search/setLocale', this.locale);
			this.$i18n.locale = this.locale;
		}
		try {
			await this.loadProduct();
			await Promise.all([
				this.$store.dispatch('list/loadAll', { ifEmpty: true }),
				this.$store.dispatch('biostandard/loadAll', { ifEmpty: true }),
				this.$store.dispatch('country/loadAll', { ifEmpty: true }),
				this.$store.dispatch('category/loadEntitesById', this.allCategories),
				this.$store.dispatch('component/loadEntitesById', this.allComponents)
			]);
		} catch (err) {
			if (err.message.includes('Too Many Requests')) {
				this.error = this.$t('searchForm.errors.tooManyRequests');
			} else {
				this.error = this.$t('searchForm.errors.unknown');
			}
		}

		if (!this.productData) {
			// this.backToSearchResults();
		}
		this.scrollIntoView();
	},

	methods: {
		async getPdf() {
			this.pdfWorking = true;
			try {
				await this.$store.dispatch('pdf/getPdf', {
					productId: this.productId,
					listId: this.listId,
					language: this.$store.state.search.selectedLocale,
					restrictStandards: this.$store.getters['search/getRestrictStandards'](this.listId)
				});
			} finally {
				this.pdfWorking = false;
			}
		},
		scrollIntoView() {
			let elem = document.getElementById('BmlProductDetail');
			if (!elem) return;
			const domRect = elem.getBoundingClientRect();
			if (!domRect || !domRect.height) return;
			window.scrollTo(0, domRect.top);
		},
		backToSearchResults() {
			this.$router.push({ name: 'PublicSearch' });
		},
		async loadProduct() {
			let entity = await this.$store.dispatch('search/loadSingleProductById', { productId: this.productId, historyDate: this.historyDate });
			this.productData = entity;
			await this.loadDistributor();
		},
		async loadDistributor() {
			const indexData = this.indexData;
			if (!indexData) {
				return;
			}
			let base = this.selectedIndexData;
			if (base && base.distributor && base.distributor.id) {
				let res = await this.$store.dispatch('companylist/loadByIds', [base.distributor.id]);
				this.distributor = res[0];
			}

			this.loadMainAddress();
		},
		loadMainAddress() {
			if (!this.distributor) {
				return;
			}
			const distributorUnwrapped = this.distributor;
			if (!distributorUnwrapped.data.addresses || distributorUnwrapped.data.addresses.length === 0) {
				return;
			}
			this.mainAddress = distributorUnwrapped.data.addresses.find(f => f.type === 'catalog' && f.catalogListId === this.listId);
			if (!this.mainAddress) {
				this.mainAddress = distributorUnwrapped.data.addresses.find(f => f.type === 'catalog' && f.catalogListId === this.baseListId);
			}
			if (!this.mainAddress) {
				this.mainAddress = distributorUnwrapped.data.addresses.find(f => f.type === 'main');
			}
		},
		extractDataFromList(field) {
			if (!this.listData) {
				return NO_RESULT;
			}
			const selectedListId = this.listId;
			const baseListId = this.baseListId;
			const baseData = this.listData;
			return extractDataFromList(baseData, field, selectedListId, baseListId);
		}
	}
};
</script>
