import de_DE from './de_DE.json';
import en_GB from './en_GB.json';
import it_IT from './it_IT.json';
import nl_NL from './nl_NL.json';
import fr_FR from './fr_FR.json';
import sv_SE from './sv_SE.json';
import es_ES from './es_ES.json';

export const languages = {
	de_DE,
	en_GB,
	it_IT,
	nl_NL,
	fr_FR,
	sv_SE,
	es_ES
};
