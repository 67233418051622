import jwtDecode from 'jwt-decode';
import { DOMAIN, KeycloakInfo } from '@/util/env.js';
import { setToken, setUsePaidRoute } from '@/util/jwt-cache.js';
import EventHub from '@/util/eventbus.js';
import { loginToDomain } from '@/repository/account.js';
import Keycloak from 'keycloak-js';

const keycloak = KeycloakInfo ? new Keycloak(KeycloakInfo) : null;

let initPromise;
let refreshTimer;
let isInit = false;

const state = {
	main: {
		token: null,
		expires: 0,
		account: null
	},
	ssoToken: null,
	ssoRefreshToken: null,
	ssoParsed: null,
	manualLogin: false,
	noRedirectAfterAccountSwitch: null,
	keycloakAccountUrl: null
};

const getters = {

	account: (state) => {
		return state.main.account;
	},

	isLoggedIn: (state) => {
		let ts = ~~(Date.now()/1000);
		return state.main.expires > ts
			&& state.main.token !== null;
	},

	currentToken: (state) => {
		return state.main.token;
	},

	hasAccountSelected: (state) => {
		return state.main.account !== null;
	},

	getValidTokenForAccount: (state) => (accountId) => {
		let ts = ~~(Date.now()/1000);
		if (state.main.account === accountId && state.main.expires > ts) {
			return state.main.token;
		}
		return null;
	},

	hasSsoLogin: (state) => {
		return state.ssoParsed && state.ssoParsed.exp > Date.now()/1000;
	},

	ssoProfileLink: () => keycloak ? keycloak.createAccountUrl() : null,

	ssoToken: (state) => state.ssoToken,

	ssoUserId: (state) => {
		return state.ssoParsed?.sub;
	},
	getKeycloakAccountUrl: (state) => {
		return state.keycloakAccountUrl;
	}
};

const actions = {

	/**
	 * should be called once at the start
	 **/
	async initApp({ dispatch, commit, state, rootState }) {
		if (!keycloak || !rootState.search.usePaidUserUi) return;
		if (!initPromise) {
			const opts = {
				onLoad: 'check-sso',
				silentCheckSsoFallback: false,
				checkLoginIframe: false,
				idToken: '',
				token: '',
				refreshToken: ''
			};
			if (state.ssoToken && state.ssoRefreshToken) {
				opts.idToken = opts.token = state.ssoToken;
				opts.refreshToken = state.ssoRefreshToken;
			}
			// console.log('keycloak init', opts);
			initPromise = keycloak.init(opts);
		}
		let auth = await initPromise;
		if (!auth) {
			commit('logout');
		}
		if (isInit) return;
		isInit = true;
		// console.log('init', auth, keycloak);
		if (auth) {
			if (keycloak.refreshToken) {
				try {
					await keycloak.updateToken(65);
				} catch (e) {
					//refresh token expired
					console.warn('unable to refresh auth, probably expired', e);
					return;
				}
			}
			await dispatch('authenticate');
		}
		if (!refreshTimer) {
			refreshTimer = setInterval(async() => {
				if (!keycloak.refreshToken) return;
				try {
					let refreshed = await keycloak.updateToken(65);
					if (refreshed) {
						commit('setSsoToken', keycloak);
						let data = await loginToDomain(state.ssoToken, state.main.account);
						commit('login', data.jwt);
					}
				} catch (e) {
					console.log('refresh token expired', e);
					//now what?
					commit('logout');
				}
			}, 1000 * 30);
		}
	},

	/**
	 * call to log in the user
	 **/
	async authenticate({ commit, dispatch, state, rootState, getters }) {
		// console.log('authenticate', keycloak);
		if (!keycloak.token) {
			let locale = rootState.search.selectedLocale;
			await keycloak.login({
				redirectUri: document.location.href,
				locale,
				kcLocale: locale
			});
		}
		commit('setSsoToken', keycloak);
		const url = await keycloak.createAccountUrl();
		commit('setKeycloakAccountUrl', url);
		let data = await loginToDomain(state.ssoToken, state.main.account);
		commit('login', data.jwt);
		await dispatch('auth/user/switchToFirstAvailableAccount', null, { root: true });
		if (getters.isLoggedIn && !getters.hasAccountSelected && state.manualLogin) {
			commit('setManualLogin', false);
			EventHub.$emit('navigate', { name: 'Registration' });
		}
	},

	register() {
		let url = keycloak.createRegisterUrl({ });
		window.location.href = url;
	},

	/**
	 * call to log out the user
	 **/
	async logout({ commit }) {
		commit('logout');
		await keycloak.logout();
	},

	/**
	 * switch zdb accounts
	 **/
	async switchAccountAndLogin({ commit, dispatch, getters, state }, accountId) {
		// errors in this function are never displayed to user? not translated for now
		if (!accountId) throw new Error('no accountId specified');
		if (accountId === state.main.account) return;
		if (state.noRedirectAfterAccountSwitch) {
			commit('setNoRedirectAfterAccountSwitch', false);
		} else {
			EventHub.$emit('navigate', { name: 'PublicSearch' });
		}
		let switchAccount = state.main.account;
		let existing = getters.getValidTokenForAccount(accountId);
		if (existing) {
			commit('login', existing);
		} else {
			let data = await loginToDomain(state.ssoToken, accountId);
			if (!data) throw new Error('Could not switch account.');
			commit('login', data.jwt);
		}
		await dispatch('auth/user/loadAccountData', null, { root: true });
		if (switchAccount) EventHub.$emit('navigate', { name: 'PublicSearch' });
	}
};

const mutations = {

	login(state, jwt) {
		// errors in this function are never displayed to user? not translated for now
		let { aud, sub, exp } = jwtDecode(jwt);
		if (aud !== DOMAIN) {
			throw new Error(`token expected for ${DOMAIN} but received for ${aud}`);
		}
		let expDate = new Date(exp * 1000);
		if (expDate < new Date()) {
			throw new Error('token is expired');
		}
		if (!sub) sub = null;
		state.main.token = jwt;
		state.main.expires = exp;
		state.main.account = sub;
		setToken(jwt);
		EventHub.$emit('auth.login');
	},

	logout(state) {
		state.main.token = null;
		state.main.account = null;
		state.main.expires = 0;
		state.ssoToken = null;
		state.ssoRefreshToken = null;
		state.ssoParsed = null;
		state.keycloakAccountUrl = null;
		setToken(null);
		setUsePaidRoute(false)
		EventHub.$emit('auth.logout');
	},

	setSsoToken(state, { token, tokenParsed, refreshToken }) {
		state.ssoToken = token;
		state.ssoParsed = tokenParsed;
		state.ssoRefreshToken = refreshToken;
	},

	setNoRedirectAfterAccountSwitch(state, value) {
		state.noRedirectAfterAccountSwitch = value;
	},

	setManualLogin(state, isManualLogin) {
		state.manualLogin = isManualLogin;
	},

	resetAccount(state) {
		state.main.account = null;
	},
	setKeycloakAccountUrl(state, url) {
		state.keycloakAccountUrl = url;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
