import Vue from 'vue';
import router from '../router';
import store from '../store';
import EventHub from '@/util/eventbus';
import { CommitSha } from '@/util/env';
import { log } from './request.js';

function checkForMaintenance(e) {
	if (!e || !e.message) return false;
	if (e.message.includes('service is currently not available. check back later')) {
		store.commit('base/setIsMaintenance');
		return true;
	}
	return false;
}

function getExtraLogData() {
	return {
		ua: navigator.userAgent,
		lang: navigator.language,
		plat: navigator.platform,
		loc: window.location.href,
		sx: screen.width,
		sy: screen.height,
		app: 'bml-pub',
		build: CommitSha
	};
}

async function logError(data) {
	try {
		await log({ ...data, ...getExtraLogData() });
	} catch (e) {
		console.log('error during error logging', e);
	}
}

window.onerror = function(message, source, lineno, colno, error) {
	console.log('window.onerror', error);
	EventHub.$emit('error', error);
	let maintenance = checkForMaintenance(error);
	if (!maintenance) {
		logError({ message, source, lineno, colno, error });
	}
};
window.addEventListener('unhandledrejection', event => {
	checkForMaintenance(event.reason);
});

EventHub.$on('navigate', route => {
	router.push(route);
});

// notification handling
EventHub.$on('error', (title, error) => {
	checkForMaintenance(error);
	if (error) store.commit('notification/add', {
		type: 'error',
		title: title || 'Error',
		body: error && error.message ? error.message : JSON.stringify(error),
		time: Date.now(),
	});
	console.log(error);
});
EventHub.$on('warning', (title, body) => {
	store.commit('notification/add', {
		type: 'warning',
		title,
		body,
		time: Date.now(),
	});
	console.log(title, '|', body);
});
EventHub.$on('message', (title, body) => {
	store.commit('notification/add', {
		type: 'message',
		title,
		body,
		time: Date.now(),
	});
	console.log(title, '|', body);
});
EventHub.$on('success', (title, body) => {
	store.commit('notification/add', {
		type: 'success',
		title,
		body,
		time: Date.now(),
	});
	console.log(title, '|', body);
});


// error handling
// if (NODE_ENV_PRODUCTION) {
Vue.config.errorHandler = function(error) {
	checkForMaintenance(error);
	console.log('vue.errorHandler', error);
	EventHub.$emit('error', error);
};
Vue.config.warnHandler = function(error) {
	checkForMaintenance(error);
	console.log('vue.warnHandler', error);
	EventHub.$emit('warning', error);
};
// }

store.dispatch('auth/token/initApp');
