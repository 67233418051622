import Store from '@/store/index';

export const PAGINATION_OPTIONS = [5, 10, 25, 50, 100];

export function getPageCount(listCount, itemsPerPage) {
	return Math.ceil(listCount / itemsPerPage);
}

export function getPages(pageCount) {
	let pages = [];

	for (let i = 1; i <= pageCount; ++i) {
		pages.push(i);
	}

	return pages;
}

export function getFirstItemIndex(startIndex) {
	return startIndex + 1;
}

export function getLastItemIndex(startIndex, entries) {
	return startIndex + entries.length;
}

export function getActivePageNum(type) {
	return Store.getters[`${type}/getActivePageNum`];
}

export function setActivePageNum(type, num) {
	Store.commit(`${type}/setActivePageNum`, num);
}

export function getPerPage(type) {
	return Store.getters[`${type}/getPerPage`];
}

export function setPerPage(type, num) {
	Store.commit(`${type}/setPerPage`, num);
}
