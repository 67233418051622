<template>
	<div>
		<b-spinner label="Loading..." v-if="!loaded" />
		<div v-else>
			<h4>
				{{ $t('singleResult.default.product.title') }}
				<template v-if="!isBaseListSelected">
					({{ listId | toListDisplay }})
				</template>
			</h4>

			<history-table-vue
				:product-data="productData"
				:list-id="listId"
				:history-date="historyDate"
				v-if="historyDate"
			/>

			<h4 v-if="historyDate">
				{{ $t('singleResult.default.product.titleHistory', { date: historyDateFormatted }) }}
			</h4>
			<table class="table">
				<tbody>
					<tr>
						<td class="font-weight-bold">
							{{ $t('singleResult.default.product.productName') }}
						</td>
						<td>
							{{ extractDataFromIndex('name') | fieldToLocale }}
						</td>
					</tr>
					<tr>
						<td class="font-weight-bold">
							{{ $t('singleResult.default.product.mainCategory') }}
						</td>
						<td>
							<template v-if="selectedMainCategory">
								{{ selectedMainCategory.id | toCategoryDisplay }}
							</template>
						</td>
					</tr>
					<tr>
						<td class="font-weight-bold">
							{{ $t('singleResult.default.product.subCategory') }}
						</td>
						<td>
							{{ subCategories.join(' // ') }}
						</td>
					</tr>
				</tbody>
			</table>
			<div v-if="isUserActive">
				<h4>
					{{ $t('singleResult.default.productDetails.title') }}
				</h4>

				<div v-if="showProductDetailsFromOtherLists">
					<div v-for="[ currentListId, currentPrintData ] in Object.entries(professionalLists)" :key="currentListId">
						<h4 v-if="listId !== currentListId">
							<router-link :to="openProductList(productId, currentListId)">
								{{ currentListId | toListDisplay }}
							</router-link>
						</h4>
						<span v-if="listId !== currentListId">
							({{ $t('singleResult.default.product.clickForDetails') }})
						</span>
						<span v-html="currentPrintData" />
						<div v-if="bvlData(currentListId)">
							<span>{{$t('singleResult.default.seeLink')}}
							</span>
							<a :href="bvlData(currentListId)" target="_blank">{{ $t('singleResult.default.linkTemplate') }}</a><br><br>
						</div>
					</div>
				</div>
				<div v-else>
					<span v-html="getPrintDataHTML()" />
				</div>
			</div>

			<div v-if="bvlData() && !showProductDetailsFromOtherLists">
				<span>{{$t('singleResult.default.seeLink')}}
				</span>
				<a :href="bvlData()" target="_blank">{{ $t('singleResult.default.linkTemplate') }}</a><br><br>
			</div>

			<template v-if="standards.length > 0">
				<h4>
					{{ $t('singleResult.default.standard.title') }}
				</h4>
				<div>
					<div v-if="detailsText">
						{{ detailsText }}
					</div>
					<template v-if="isBaseListSelected">
						<br>{{ $t('singleResult.default.productDetails.complianceNationalLegislation') }}<br>
					</template>
					<br> <br>
				</div>
				<table class="table">
					<thead>
						<tr>
							<th>
								{{ $t('singleResult.default.standard.standard') }}
							</th>
							<th>
								{{ $t('singleResult.default.standard.restriction') }}
							</th>
							<th>
								{{ $t('singleResult.default.standard.validUntil') }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="([ standard, data ], index) in standards" :key="`${standard}-${index}`">
							<td>
								{{ standard | toBiostandardDisplay }}
							</td>
							<td>
								{{ getRestrictionLabel(data) | fieldToLocale }}
							</td>
							<td>
								{{ unwrapValue(data.end) | toDateFormat }}
							</td>
						</tr>
					</tbody>
				</table>
			</template>

			<template v-if="distributor">
				<h4>
					{{ $t('singleResult.default.distributor.title') }}
				</h4>
				<table class="table">
					<tbody>
						<tr>
							<td class="font-weight-bold">
								{{ $t('singleResult.default.distributor.company') }}
							</td>
							<td>
								{{ companyName }}
							</td>
						</tr>
						<tr>
							<td class="font-weight-bold">
								{{ $t('singleResult.default.distributor.address') }}
							</td>
							<td>
								{{ extractField(mainAddress, 'street') }},
								{{ extractField(mainAddress, 'zip') }} -
								{{ extractField(mainAddress, 'city') }},
								{{ extractField(mainAddress, 'country') | toCountryDisplay }}
							</td>
						</tr>
						<tr>
							<td class="font-weight-bold">
								{{ $t('singleResult.default.distributor.phone') }}
							</td>
							<td>
								{{ extractField(mainAddress, 'phone') }}
							</td>
						</tr>
						<tr>
							<td class="font-weight-bold">
								{{ $t('singleResult.default.distributor.email') }}
							</td>
							<td v-if="mainAddress.email">
								<a :href="`mailto:${extractField(mainAddress, 'email')}`"> {{ extractField(mainAddress, 'email') }} </a>
							</td>
							<td v-else>
								{{ extractField(mainAddress, 'email') }}
							</td>
						</tr>
						<tr>
							<td class="font-weight-bold">
								{{ $t('singleResult.default.distributor.website') }}
							</td>
							<td>
								<a :href="formatWebsiteLink(website)" target="_blank">{{ website ? website.replace(/^(http(s)?):\/\//, "") : "" }}</a>
							</td>
						</tr>
					</tbody>
				</table>
			</template>
		</div>
	</div>
</template>

<script>
import { unwrapValue, fieldToLocale, extractDataFromList, extractField, NO_RESULT} from '@/util/locale';
import { SearchOptionsProvider } from '@/services/SearchOptionsProvider';
import HistoryTableVue from './HistoryTable.vue';
import { toDateFormat } from '@/plugins/filters';

const EXPAND_CATEGORIES = ['plant-protection-agents'];
const BVL_PERMIT_CONSTANT = '6fe4d1c5-3c3b-43ca-8879-6a026d9e535f'; // "Deutschland, BVL-Zulassungsnr."
const BVL_LISTING_CONSTANT = '7ca746e3-672e-4fbe-8372-95bda06e9c23'; // "Deutschland, BVL-Listungsnr."
const GERMAN_LIST = '3c57d0fe-64d5-4aae-8471-b3e9746b7086'; // Liste DE

export function mergeArrays(obj, base) {
	const merged = [];

	for (const entry of obj) {
		let curr = [];
		curr.push(entry[0]);

		let baseObj;
		for (const baseArr of base) {
			if (baseArr[0] === entry[0]) {
				baseObj = baseArr;
				break;
			}
		}

		if (baseObj) {
			curr.push(Object.assign(entry[1], baseObj[1]));
		} else {
			curr.push(entry[1]);
		}

		merged.push(curr);
	}

	const objKeys = obj.map(f => f[0]);
	for (const baseArr of base) {
		const baseKey = baseArr[0];
		if (!objKeys.includes(baseKey)) {
			merged.push(baseArr);
		}
	}

	return merged;
}


export default {
	name: 'DefaultSearchResult',

	props: {
		productData: {
			type: Object,
			required: true
		},
		distributor: {
			type: Object,
			default: null
		},
		mainAddress: {
			type: Object,
			default: null
		}
	},

	components: {
		HistoryTableVue
	},

	data() {
		return {
			loaded: false,
			printData: null
		};
	},

	computed: {
		professionalLists() {
			for (let listId of Object.keys(this.printData)) {
				if (this.$store.getters['list/isHobbyList'](listId)) delete this.printData[listId];
			}
			return this.printData;
		},
		isUserActive() {
			return true;
			// if (!this.$store.getters['search/usePaidUserUi']) return true;
			// return this.$store.getters['auth/user/isUserActive'];
		},
		website() {
			let val = this.extractField(this.mainAddress, 'website');
			if (!val) {
				val = this.distributor.data.baseData.website;
			}
			return val;
		},
		companyName() {
			if (this.mainAddress.additionalNameIsSet) {
				return extractField(this.mainAddress, 'name');
			} else {
				return this.distributor.data.baseData.name;
			}
		},
		isBaseListSelected() {
			return this.listId === this.baseListId;
		},
		showProductDetailsFromOtherLists() {
			return !!(this.isBaseListSelected && this.selectedMainCategory && EXPAND_CATEGORIES.includes(this.selectedMainCategory.data.mainCategoryKey));
		},
		standards() {
			if (!this.selectedListData) {
				return [];
			}

			let allStandards = [];

			if (this.selectedListData._approval) {
				allStandards = Object.entries(this.selectedListData._approval);
			}
			const currentList = this.list;
			allStandards = allStandards.filter(standardEntry => currentList.data.biostandards.includes(standardEntry[0]) || currentList.data.baseStandards && currentList.data.baseStandards.includes(standardEntry[0]));

			if (this.showBaseListStandards && currentList.data.baseStandards.length) {
				const additionalBaseStandards = currentList.data.baseStandards;
				let baseStandardsFound = [];
				additionalBaseStandards.forEach(additionalStandardId => {
					for (const listData of Object.values(this.listData)) {
						if (listData._approval[additionalStandardId]) {
							baseStandardsFound.push([additionalStandardId, listData._approval[additionalStandardId]]);
						}
					}
				});
				if (baseStandardsFound.length) allStandards = mergeArrays(allStandards, baseStandardsFound);
			}

			if (allStandards) {
				allStandards = allStandards.filter(standard => {
					const baseData = this.$store.getters['biostandard/byId'](standard[0]);
					if (!baseData) {
						return false;
					}

					if (!this.$store.getters['biostandard/isStandardActiveInList'](standard[0], this.listId)) return false;

					let result = true;

					if (standard[1].active) {
						result = result && unwrapValue(standard[1].active);
					}

					if (standard[1].active2) {
						result = result && unwrapValue(standard[1].active2);
					}

					result = result && this.isApproved(standard[1]);

					return result;
				});
				const restrictStandards = this.$store.getters['search/getRestrictStandards'](this.listId);
				if (restrictStandards) allStandards = allStandards.filter(standard => restrictStandards.includes(standard[0]));
				allStandards = allStandards.sort((a, b) => {
					if (!a[0] || !b[0]) return 0;
					const standardA = this.$store.getters['biostandard/byId'](a[0]);
					const standardB = this.$store.getters['biostandard/byId'](b[0]);
					if (!standardA || !standardB) return 0;
					return standardA.data.publicOrder > standardB.data.publicOrder ? 1 : -1;
				});
				return allStandards;
			}

			return [];
		},
		detailsText() {
			if (this.standards && this.standards.length) {
				let standard = this.standards.find(entry => {
					let standardEntry = this.$store.getters['biostandard/byId'](entry[0]);
					return standardEntry && standardEntry.data.publicOrder > 0;
				});
				if (standard) {
					let standardEntry = this.$store.getters['biostandard/byId'](standard[0]);
					return standardEntry && standardEntry.data.detailsText && fieldToLocale(standardEntry.data.detailsText) ? fieldToLocale(standardEntry.data.detailsText) : '';
				}
			}
			return '';
		},
		allCategories() {
			const categories = this.extractDataFromList('_categoryTree');
			if (!Array.isArray(categories)) {
				return [];
			}
			return categories;
		},
		selectedMainCategory() {
			// const categories = Array.from(getTotalSelection('category', this.allCategories));
			return this.$store.getters['category/mainCategoryFromIds'](this.allCategories);
		},
		subCategories() {
			let categories = this.$store.getters['category/byIds'](this.allCategories);
			categories = categories.sort((entry1, entry2) => {
				let path1 = this.$store.getters['category/pathToRoot']([entry1.id]);
				let path2 = this.$store.getters['category/pathToRoot']([entry2.id]);
				return path1.length > path2.length ? 1 : -1;
			});

			return categories
				.filter(f => !!f.data.parent)
				.map(f => fieldToLocale(f.data.title));
		},
		productId() {
			return this.$route.params.product_id;
		},
		listId() {
			return this.$route.params.list_id;
		},
		historyDate() {
			return this.$route.params.date;
		},
		list() {
			return this.$store.getters['list/byId'](this.listId);
		},
		showBaseListStandards() {
			const list = this.list;
			if (list) {
				return !!list.data.showBaseListStandards || list.data.isBasedata;
			}

			return false;
		},
		baseListId() {
			return this.$store.getters['list/baseListId'];
		},
		listData() {
			if (!this.productData) {
				return null;
			}
			return this.productData.data.listData;
		},
		baseListData() {
			const listData = this.listData;
			if (listData) {
				return listData[this.baseListId];
			}
			return null;
		},
		selectedListData() {
			const listData = this.listData;
			if (listData) {
				return listData[this.listId];
			}
			return null;
		},
		indexData() {
			if (!this.productData) {
				return null;
			}
			const productDataUnwrapped = this.productData.data;
			if (productDataUnwrapped.indexData) {
				return productDataUnwrapped.indexData;
			}
			return null;
		},
		baseIndexData() {
			const indexData = this.indexData;
			if (indexData) {
				return indexData[this.baseListId];
			}
			return null;
		},
		selectedIndexData() {
			const indexData = this.indexData;
			if (indexData) {
				return indexData[this.listId];
			}
			return null;
		},
		historyDateFormatted() {
			if (!this.historyDate) return '';
			return toDateFormat(this.historyDate);
		}
	},

	watch: {
		listId(nv, ov) {
			if (nv === ov) return;
			this.$store.dispatch('display/changeList', { productData: this.productData, listId: nv });
		}
	},

	async created() {
		this.loaded = false;
		await this.$store.dispatch('list/loadAll', { ifEmpty: true });
		await this.$store.dispatch('permit/loadAll', { ifEmpty: true });
		await this.ensureSelectOptionsInCache();
		await this.ensureRestrictionTextsInCache();
		await this.$store.dispatch('display/init', { productDataObj: this.productData, productId: this.productId, listId: this.listId, standard: null, historyDate: this.historyDate });
		if (this.showProductDetailsFromOtherLists) await this.getPrintDataForAllLists();
		this.loaded = true;
	},

	methods: {
		unwrapValue(obj) {
			return unwrapValue(obj);
		},
		isFieldEmpty(field) {
			const value = fieldToLocale(this.extractDataFromList(field));
			return !value || value.trim() === '' || value === NO_RESULT;
		},
		extractDataFromList(field) {
			if (!this.listData) {
				return NO_RESULT;
			}

			const selectedListId = this.listId;
			const baseListId = this.baseListId;
			const baseData = this.listData;

			return extractDataFromList(baseData, field, selectedListId, baseListId);
		},
		extractDataFromIndex(field) {
			if (!this.indexData) {
				return NO_RESULT;
			}

			const selectedListId = this.listId;
			const baseListId = this.baseListId;
			const baseData = this.indexData;

			return extractDataFromList(baseData, field, selectedListId, baseListId);
		},
		extractField(obj, field) {
			const value = extractField(obj, field);
			if (value === NO_RESULT) {
				return null;
			}
			return value;
		},
		formatWebsiteLink(website) {
			return website && website.match(/^(http(s)?)/) ? website : `https://${website}`;
		},
		isApproved(approval) {
			if (!approval.begin) {
				return false;
			}

			let now = new Date();
			let begin = new Date(approval.begin);
			if (begin > now) {
				return false;
			}

			if (approval.end) {
				let end = new Date(approval.end);
				if (end < now) {
					return false;
				}
			}

			return true;
		},

		getPrintDataHTML() {
			return this.$store.getters['display/getPrintDataHTML']();
		},

		async getPrintDataForAllLists() {
			if (!this.printData) this.printData = {};
			let listIds = Object.keys(this.listData);
			let filteredLists = this.$store.getters['list/lists'];
			listIds = listIds.filter(listId => filteredLists.find(list => list.id === listId));
			this.printData[this.baseListId] = this.getPrintDataHTML();
			let baseListIndex = listIds.findIndex(listId => listId === this.baseListId);
			listIds.splice(baseListIndex, 1);
			if (this.isBaseListSelected || this.$store.state.search.euMode) {
				listIds = listIds.filter(listId => {
					let currentList = this.$store.getters['list/byId'](listId);
					return currentList.data.targetAudience !== 'hobby';
				});
				let listOptions = SearchOptionsProvider.getListOptions();
				listIds = listIds.filter(list => listOptions.find(listOption => listOption.id === list));
			}
			for (let listId of listIds) {
				await this.$store.dispatch('display/changeList', { productData: this.productData, listId });
				this.printData[listId] = this.getPrintDataHTML();
			}
		},

		getRestrictionLabel(approval) {
			let label = '';
			if (approval.restriction && fieldToLocale(approval.restriction).trim() && fieldToLocale(approval.restriction).trim() !== '-') {
				label = approval.restriction;
			} else if (approval.restrictionTextId && approval.restrictionTextId.selectValue) {
				let text = this.$store.getters['restrictiontext/byId'](approval.restrictionTextId.selectValue);
				if (text && text.data.text && fieldToLocale(text.data.text).trim() && fieldToLocale(text.data.text).trim() !== '-') {
					label = text.data.text;
				}
			}
			return label;
		},

		async ensureSelectOptionsInCache() {
			await this.$store.dispatch('selectoptions/ensureOptionsInCache', this.productData);
		},

		async ensureRestrictionTextsInCache() {
			await this.$store.dispatch('restrictiontext/ensureRestrictionTextsInCache', this.productData);
		},

		openProductList(productId, listId) {
			return {
				name: 'SingleSearchResult',
				params: {
					product_id: productId,
					list_id: listId,
					locale: this.$store.state.search.selectedLocale
				}
			};
		},

		bvlData(listId) {
			let currentListId = listId || this.listId;
			let selectedListData = listId ? this.listData[listId] : this.selectedListData;
			if (currentListId !== GERMAN_LIST || this.selectedMainCategory.data.mainCategoryKey !== 'plant-protection-agents' || !selectedListData) {
				return false;
			}
			if (selectedListData['tab-zul'] && selectedListData['tab-zul'].table && selectedListData['tab-zul'].table.length) {
				let bvlRow = selectedListData['tab-zul'].table.find(row => row['zul-s1'] && (row['zul-s1'].selectValue === BVL_PERMIT_CONSTANT || row['zul-s1'].selectValue === BVL_LISTING_CONSTANT));
				if (bvlRow) {
					let bvlPermit = this.$store.getters['permit/byId'](bvlRow['zul-s1'].selectValue);
					if (bvlPermit) {
						let bvlText = bvlRow['zul-s2'] && bvlRow['zul-s2'].textValue;
						if (!bvlPermit || !bvlPermit.data.linkTemplate || !bvlText) return false;
						return bvlPermit.data.linkTemplate.replace('%s', bvlText);
					}
				}
				return false;
			}
			return false;
		}
	},
};
</script>

