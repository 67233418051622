import { getEntitiesById } from '@/repository/system.js';

const state = {

	restrictionTexts: []

};

const getters = {

	byId: (state) => (id) => {
		return state.restrictionTexts.find(r => r.id === id);
	},

	all: (state) => {
		return state.restrictionTexts;
	}

};

function getRestrictionTextIds(productData) {
	let ids = new Set();
	for (let listData of Object.values(productData)) {
		if (!listData._approval) {
			continue;
		}
		for (let approval of Object.values(listData._approval)) {
			if (approval.restrictionTextId && approval.restrictionTextId.selectValue) {
				ids.add(approval.restrictionTextId.selectValue);
			}
		}
	}
	return Array.from(ids);
}

const actions = {

	async ensureRestrictionTextsInCache({ state, commit }, productData) {
		let textIds = getRestrictionTextIds(productData.data.listData);
		if (!textIds.length) {
			return;
		}
		let newTextIds = textIds.filter(textId => !state.restrictionTexts.find(entry => entry.id === textId));
		if (!newTextIds.length) {
			return;
		}
		const entries = await getEntitiesById(newTextIds);
		commit('setEntries', entries);
	}

};

const mutations = {

	setEntries(state, entriesToSet) {
		const entryLabel = 'restrictionTexts';
		for (const entryToSet of entriesToSet) {
			const entryIndex = state[entryLabel].findIndex(entry => entry.id === entryToSet.id);
			if (entryIndex === -1) {
				state[entryLabel].push(entryToSet);
			}
		}
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
