import { get, post } from '@/util/request.js';
import { getPubRoute } from '../util/jwt-cache';
import { sleep } from '@/util/sleep';

export async function getPdf(productId, listId, language, restrictStandards) {
	let params = { productId, listId, language };
	if (restrictStandards && restrictStandards.length) params.restrictStandards = restrictStandards;
	let { ticketNumber } = await post(`bml/${getPubRoute()}/pdfproduct`, params);
	let start = Date.now();
	do {
		let { done, result, error } = await get(`bml/${getPubRoute()}/getpdfandstatus`, { ticketNumber });
		if (error) throw new Error(error);
		if (done) return result;
		await sleep(500);
	} while (Date.now() - start < 1000 * 10);
	throw new Error('timeout on retrieving pdf');
}

export function getPdfWithQueue(reportType, data) {
	return post(`bml/${getPubRoute()}/pdfaddjob`, { reportType, ...data });
}

export function getpdfandstatusqueue(ticketNumber) {
	return get(`bml/${getPubRoute()}/getpdfandstatusqueue`, ticketNumber);
}
