import { fieldToLocale } from '@/util/locale';

const getters = {

	getRelevantApprovals: (state, getters, rootState, rootGetters) => (listId) => {
		const list = rootGetters['list/byId'](listId);
		if (!list) return [];
		const standards = list.data.biostandards;
		return rootState.biostandard.biostandards.filter(b => standards.includes(b.id));
	},

	getMustHaveApprovals: (state, getters, rootState, rootGetters) => (listId) => {
		const backendParams = rootGetters['search/getBackendParameters'];
		const lastSearch = rootGetters['search/lastSearch'];
		let all = [].concat(backendParams.mandatoryStandard);
		if (lastSearch) all = all.concat(lastSearch.standard);
		return rootState.biostandard.biostandards.filter(b => all.includes(b.id));
	},

	getApprovalDataWithParent: (state, getters, rootState, rootGetters) => (listData, baseData, listId) => {
		let currentApprovalRows = JSON.parse(JSON.stringify(getters.getApprovalData(listData, baseData)));
		let relevantBiostandards = JSON.parse(JSON.stringify(getters.getRelevantApprovals(listId)));

		// filter only those biostandards that are currently relevant for this list
		relevantBiostandards = relevantBiostandards
			.filter((approval) => {
				const id = approval.id;

				if (!currentApprovalRows[id] || !currentApprovalRows[id].begin) return false;

				let now = new Date();
				let begin = new Date(currentApprovalRows[id].begin);
				if (begin > now) return false;
				if (currentApprovalRows[id].end) {
					let end = new Date(currentApprovalRows[id].end);
					if (end < now) return false;
				}
				return true;
			});

		const isRestrictionEmpty = function(value) {
			if ((!value.restriction || !fieldToLocale(value.restriction).trim() || fieldToLocale(value.restriction).trim() === '-') && (!value.restrictionTextId || !value.restrictionTextId.selectValue)) {
				return true;
			}
			let isEmpty = true;

			isEmpty = !fieldToLocale(value.restriction).trim() || fieldToLocale(value.restriction).trim() === '-';
			if (isEmpty && value.restrictionTextId && value.restrictionTextId.selectValue) {
				let text = rootGetters['restrictiontext/byId'](value.restrictionTextId.selectValue);
				return !text || !fieldToLocale(text.data.text);
			}

			return isEmpty;
		};

		// now find the approvals that don't have any restriction and swap those restrictions with the corresponding parents
		for (let [id, value] of Object.entries(currentApprovalRows)) {
			// check if current restriction is empty
			if (!relevantBiostandards.find(f => f.id === id)) {
				continue;
			}

			if (isRestrictionEmpty(value)) {
				// if the biostandard has an restriction, return that restriction
				let parentStandards = rootGetters['biostandard/toRoot'](id);
				if (!parentStandards) {
					continue;
				}

				for (const parentStandard of parentStandards) {
					const parentApproval = currentApprovalRows[parentStandard.id];
					if (!parentApproval || isRestrictionEmpty(parentApproval)) {
						continue;
					}

					value.restriction = parentApproval.restriction;
					if (!fieldToLocale(value.restriction).trim() || fieldToLocale(value.restriction).trim() === '-' && parentApproval.restrictionTextId && parentApproval.restrictionTextId.selectValue) {
						let text = rootGetters['restrictiontext/byId'](parentApproval.restrictionTextId.selectValue);
						if (text && fieldToLocale(text.data.text)) {
							value.restriction = text.data.text;
						}
					}
					break;
				}
			}
		}

		return currentApprovalRows;
	},

	getApprovalData: () => (listData, baseData) => {
		if (!listData) return {};
		if (listData && listData._approval) {
			return listData._approval;
		}

		if (baseData && baseData._approval) {
			return baseData._approval;
		}

		return {};
	},

	isApprovedForList: (state, getters, rootState, rootGetters) => (approvalHistory, listId, historyDate) => {
		const relevant = rootGetters['biostandard/allActiveInList'](listId).map(e => e.id);
		const now = historyDate ? new Date(historyDate) : new Date();
		return !!approvalHistory.find(entry => {
			if (entry.listId !== listId || !entry.begin || !relevant.includes(entry.biostandardId)) return false;
			let begin = new Date(entry.begin);
			if (begin > now) return false;
			if (entry.end) {
				let end = new Date(entry.end);
				if (end < now) return false;
			}
			return true;
		});
	}

};

export default {
	namespaced: true,
	getters
};
