import { getAccountDetails, listAccountsForUser, unregisterAccount, updateAccount, registerWithDomainByInvite, registerWithDomain } from '@/repository/account.js';
import { getEntitiesById } from '@/repository/system.js';
import EventHub from '@/util/eventbus.js';
import { setUsePaidRoute } from '@/util/jwt-cache.js';
import { i18n } from '@/main.js';

/*
 * everything for your own current user
 */

const state = {
	details: {},
	loaded: false,
	availableAccounts: []
};

const getters = {
	accountDetails: state => {
		return state.details;
	},
	availableAccounts: (state, getters, rootState, rootGetters) => {
		let isAdmin = rootGetters['country/isAdmin'];
		return state.availableAccounts.filter(a => {
			if (a.data.registrationOptionName === 'editor') return false;
			if (isAdmin) {
				return a.data.registrationOptionName === 'adm';
			} else {
				return a.data.registrationOptionName !== 'adm';
			}
		});
	},
	isLoaded: (state) => {
		return state.loaded;
	},
	accountName: (state) => state.details?.userData?.mainAddress?.name,
	hasTosAccepted: (state) => {
		if (!state.details || !state.details.staticData || !state.details.staticData.tosAcceptRequired) return true;
		return state.details.userData.tosAccepted;
	},
	isUserActive: (state, getters, rootState, rootGetters) => {
		if (!rootGetters['search/usePaidUserUi']) return false;
		return state.details && state.details.indexfields && state.details.indexfields.active;
	},
	hasCompanyType: (state) => {
		return state.availableAccounts.find(account => account.data.staticData && account.data.staticData.role === 'comp' && account.data.staticData.companyLink);
	}
};

const actions = {

	async loadAccountData({ commit, rootGetters }) {
		let accountDetails = {};
		if (rootGetters['auth/token/account']) {
			accountDetails = await getAccountDetails();
		}
		commit('setDetails', accountDetails);
		if (!rootGetters['auth/user/hasTosAccepted']) {
			EventHub.$emit('navigate', { name: 'AcceptTos' });
		}
	},

	async loadAvailableAccounts({ commit }) {
		let list = await listAccountsForUser();
		if (!list.length) {
			commit('setAvailableAccounts', []);
			return;
		}
		let entities = await getEntitiesById(list);
		commit('setAvailableAccounts', entities);
	},

	async switchToFirstAvailableAccount({ dispatch, getters, rootGetters, commit }) {
		await dispatch('loadAvailableAccounts');
		if (getters.availableAccounts.length > 0) {
			if (rootGetters['auth/token/account'] && getters.availableAccounts.find(a => a.id === rootGetters['auth/token/account'])) {
				await dispatch('loadAccountData');
			} else {
				await dispatch('auth/token/switchAccountAndLogin', getters.availableAccounts[0].id, { root: true });
			}
		} else {
			commit('setDetails', {});
			await commit('auth/token/resetAccount', null, { root: true });
		}
	},

	async unregisterFromAccount({ rootGetters, dispatch }, account) {
		if (!account) account = rootGetters['auth/token/account'];
		if (!account) throw new Error('no account is selected');
		await unregisterAccount(account);
		dispatch('switchToFirstAvailableAccount');
	},

	async updateUserData({ state, commit }, data) {
		let accountData = await updateAccount(data);
		let details = JSON.parse(JSON.stringify(state.details));
		details.userData = accountData.userData;
		commit('setDetails', details);
	},

	async registerWithInvite({ dispatch, rootState }, { inviteId, registrationName }) {
		await dispatch('auth/token/initApp', null, { root: true });
		let token = rootState.auth.token.ssoToken;
		let accountId = await registerWithDomainByInvite(inviteId, registrationName, token);
		await dispatch('auth/token/switchAccountAndLogin', accountId, { root: true });
	},

	async registerWithoutInvite({ dispatch, rootState }, { userData, registrationName }) {
		await dispatch('auth/token/initApp', null, { root: true });
		let token = rootState.auth.token.ssoToken;
		let accountId;
		try {
			accountId = await registerWithDomain(registrationName, userData, token);
		} catch (e) {
			let errorMessage = e.previous && e.previous.message ? e.previous.message : e.message;
			EventHub.$emit('error', i18n.t('paiduser.messages.registrationFailed'), i18n.t('paiduser.messages.registrationFailed') + ': ' + errorMessage);
			return false;
		}
		await dispatch('auth/token/switchAccountAndLogin', accountId, { root: true });
		return true;
	},

	async setTosAccepted({ dispatch, rootGetters }) {
		let userData = rootGetters['auth/user/accountDetails'].userData;
		if (!userData) return;
		userData.tosAccepted = true;
		await dispatch('updateUserData', userData);
		await dispatch('loadAccountData');
	}
};

const mutations = {

	setDetails(state, details) {
		state.details = details;
		state.loaded = !!Object.keys(details).length;
		setUsePaidRoute(state.details && state.details.indexfields && state.details.indexfields.active);
	},

	setAvailableAccounts(state, list) {
		state.availableAccounts = list;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
