
const state = {
	list: '',
	productTitle: '',
	productTitleData: [],
	distributor: '',
	distributorData: [],
	standard: '',
	category: [],
	substance: '',
	hobbygarden: [],
	component: [],
	cultureDetailDescription: '',
	pestOrganism: '',
	applicationEdited: '',
	applicationParasites: '',
	animalKinds: [],
	psmCulture: []
};

const getters = {
	allParameters: state => {
		return JSON.parse(JSON.stringify(state));
	},
	noCategory: (state, getters) => {
		let all = getters.allParameters;
		delete all.category;
		return all;
	},
	noHobby: (state, getters) => {
		let all = getters.allParameters;
		delete all.hobbygarden;
		return all;
	},
	noComponent: (state, getters) => {
		let all = getters.allParameters;
		delete all.component;
		return all;
	}
};

const actions = {

};

const mutations = {

	setParam(state, { key, value }) {
		if (!(key in state)) throw new Error(`unknown state key: ${key}`);
		// console.log('setParam', key, value);
		state[key] = value;
	},

	setParams(state, conf) {
		Object.entries(conf).forEach(([k, v]) => {
			if (k in state) {
				state[k] = v;
			}
		});
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
