import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import createLogger from 'vuex/dist/logger';
import base from './base';
import list from './list';
import field from './field';
import category from './category';
import component from './component';
import hobbygarden from './hobbygarden';
import biostandard from './biostandard';
import country from './country';
import selectoptions from './selectoptions';
import companylist from './companylist';
import approvals from './approvals';
import restrictiontext from './restrictiontext';
import permit from './permit';
import locale from './locale';
import display from './display';
import search from './search';
import productlist from './productlist';
import pdf from './pdf';
import auth from './auth';
import notification from './notification.js';
import searchUi from './search-ui.js';

Vue.use(Vuex);

const persistence = createPersistedState({
	key: 'bmlpub_auth_locale',
	storage: window.localStorage,
	paths: ['auth.token', 'pdf.ticketNumber']
});

const logger = store => {
	// called when the store is initialized
	store.subscribe((mutation) => {
		console.debug('mutation: ', mutation);
	});
};

const plugins = process.env.NODE_ENV === 'development'
	? [ persistence, logger ]
	: [ persistence ];

export default new Vuex.Store({
	strict: true,
	plugins,
	modules: {
		base,
		auth,
		list,
		field,
		category,
		component,
		hobbygarden,
		biostandard,
		country,
		selectoptions,
		companylist,
		approvals,
		restrictiontext,
		permit,
		locale,
		display,
		search,
		searchUi,
		productlist,
		pdf,
		notification
	}
});
