<template>
	<div>
		<b-card>
			<h1>{{ $t('paiduser.editUserData.header') }}</h1>
			<user-data-form :user-data="userData"
				@save="save"
				form-type="editData"
			/>
		</b-card>
		<b-card>
			<b-button @click="deleteAccount" variant="danger">
				{{ $t('paiduser.editUserData.deleteAccount') }}
			</b-button>
		</b-card>
	</div>
</template>


<script>
import EventBus from '@/util/eventbus.js';
import UserDataForm from '@/components/UserDataForm';

export default {

	name: 'EditUserData',

	components: {
		UserDataForm
	},

	props: {
		userData: {
			type: Object,
			default: () => {
				return {
					mainAddress: {
						name: '',
						street: '',
						citycode: '',
						city: '',
						countrycode: '',
						email: ''
					},
					invoiceAddress: {
						name: '',
						street: '',
						citycode: '',
						city: '',
						countrycode: '',
						email: '',
					},
					controlNumber: '',
					contactPersonFirstname: '',
					contactPersonLastname: '',
					contactPhone: '',
					taxId: '',
					commentUser: '',
					tosAccepted: false,
					associations: null
				};
			}
		}
	},

	data() {
		return {
		};
	},

	methods: {
		async save(userData) {
			if (userData.role) delete userData.role;
			await this.$store.dispatch('auth/user/updateUserData', userData);
			const updatedAccountData = this.$store.getters['auth/user/accountDetails'];
			if (updatedAccountData) {
				EventBus.$emit('success', this.$t('paiduser.messages.operationSuccessful'), this.$t('paiduser.messages.accountDataUpdated'));
				this.form = JSON.parse(JSON.stringify(updatedAccountData.userData));
			}
		},

		async deleteAccount() {
			await this.$store.dispatch('auth/user/unregisterFromAccount');
			EventBus.$emit('success', this.$t('paiduser.messages.operationSuccessful'), this.$t('paiduser.messages.accountDeleted'));
			let hasPaidAccounts = this.$store.getters['auth/user/availableAccounts'].length;
			this.$router.push({ name: hasPaidAccounts ? 'UserCenter' : 'PublicSearch' });
		}

	},

};
</script>
