import { getPubRoute } from '../util/jwt-cache';
import { get, post } from '@/util/request.js';

export async function loadCompanies(ids) {
	let res = await get(`bml/${getPubRoute()}/companies`, { ids });
	return res;
}

export function register(data) {
	return post('bml/pub/register', data);
}

export function autocomplete({ type, value, searchConfiguration, list, language }) {
	return post(`bml/${getPubRoute()}/autocomplete`, {
		type,
		value,
		searchConfiguration,
		list,
		language
	});
}

export function checkBioC(controlNumber) {
	return post(`bml/pub/checkforbiocdata`, {
		controlNumber
	});
}

export function doesPaidUserExist(data) {
	return post(`bml/pub/doespaiduserexist`, data);
}