import { get, post } from '@/util/request.js';
import LRU from 'lru-cache';
import { getPubRoute } from '../util/jwt-cache';

let ecListPromise;
let rootsLoaded = {
	category: false,
	component: false,
	hobbygarden: false
};

const cache = new LRU({
	maxAge: 1000 * 60 * 10,
	max: 2000
});

function getEntityclassList() {
	return get('eav/entityclass/list');
}

export async function getEntityclassByExternalId(extId) {
	if (!ecListPromise) {
		ecListPromise = getEntityclassList();
	}
	let list = await ecListPromise;
	let ec = list.find(e => e.externalId === extId);
	if (!ec) throw new Error(`ec not found: ${extId}`);
	return ec;
}

export function getAttributesetByExternalId(extId) {
	return get('eav/attribute/getbyexternalid', extId);
}

export async function getSingle(id) {
	if (cache.get(id)) return cache.get(id);
	let [ res ] = await get('search/search/ids', { ids: [id] });
	if (!res) throw new Error(`entity with id: ${id} not found`);
	cache.set(id, res);
	return res;
}

export async function search(entityclass, filter, pagination, sorting) {
	const endpoint = 'search/search/execute';
	const searchWindow = 150;
	let result = [];
	let searchRequest = {
		pagination: {
			start: 0,
			limit: searchWindow
		}
	};
	if (entityclass) searchRequest.entityClass = entityclass;
	if (filter) searchRequest.attributeFilter = filter;
	if (pagination) searchRequest.pagination = pagination;
	if (sorting) searchRequest.sorting = sorting;

	let temp;
	do {
		temp = await post(endpoint, searchRequest);

		result = result.concat(temp);
		if (!pagination) {
			searchRequest.pagination.start += searchWindow;
		}
	} while (!pagination && temp && temp.length >= searchWindow);

	return result;
}

export async function countEntries(entityClass, filter) {
	const endpoint = 'search/search/count';
	let searchRequest = {
		entityClass
	};

	if (filter) searchRequest.attributeFilter = filter;

	const result = await post(endpoint, searchRequest);
	return result;
}

export async function searchProducts(searchConfiguration, pagination, sorting, idsOnly) {
	const endpoint = `bml/${getPubRoute()}/search`;
	let searchRequest = {};

	if (pagination) {
		searchRequest.pagination = pagination;
	}

	if (sorting) {
		searchRequest.sorting = sorting;
	}

	if (idsOnly) {
		searchRequest.idsOnly = idsOnly;
	}

	searchRequest.searchConfiguration = searchConfiguration;
	let result = await post(endpoint, searchRequest);
	return result;
}

export async function aggregateSearch(searchConfiguration, parameters) {
	const endpoint = `bml/${getPubRoute()}/aggregateSearch`;
	let searchRequest = {
		searchConfiguration,
		aggregateType: parameters.aggregateType
	};
	let result = await post(endpoint, searchRequest);
	return result;
}

export async function countProducts(searchConfiguration) {
	const endpoint = `bml/${getPubRoute()}/count`;
	let searchRequest = {};
	searchRequest.searchConfiguration = searchConfiguration;
	let result = await post(endpoint, searchRequest);
	return result;
}

function getEntitiesFromCacheWithSpliceIds(ids) {
	let entities = [];
	let restIds = [];
	for (let i=0; i<ids.length; i++) {
		const entity = cache.get(ids[i]);
		if (entity) {
			entities.push(entity);
		} else {
			restIds.push(ids[i]);
		}
	}
	return [entities, restIds];
}

function saveToCache(entities) {
	if (!entities || !entities.length) return;
	for (const entity of entities) {
		cache.set(entity.id, entity);
	}
}

export async function getEntitiesById(ids) {
	if (!ids || !ids.length) return [];
	let [entities, restIds] = getEntitiesFromCacheWithSpliceIds(ids);
	if (restIds.length > 0) {
		const entitesFetched = await post('search/search/ids', { ids: restIds });
		for (const entity of entitesFetched) {
			cache.set(entity.id, entity);
		}
		entities = entities.concat(entitesFetched);
	}
	return entities;
}

// load children of categories or components and save to parent
export async function loadParentsWithChildren({ type, parentIds }) {
	let [parents, restIds] = getEntitiesFromCacheWithSpliceIds(parentIds);
	let filter = { $or: [] };
	if (restIds && restIds.length) {
		const fetchedEntites = await getEntitiesById(restIds);
		parents = parents.concat(fetchedEntites);
	}
	for (const parent of parents) {
		if (!parent.children) {
			parent.children = [];
			filter.$or.push(
				{
					field: '/data/parent',
					comparison: 'eq',
					value: parent.id
				}
			);
		}
	}
	if (!filter.$or.length) return parents;
	const entityClass = await getEntityclassByExternalId(type);
	const children = await search(entityClass.id, filter);
	for (const child of children) {
		let parentIndex = parents.findIndex(parent => parent.id === child.data.parent);
		if (parentIndex !== -1 && !parents[parentIndex].children.find(currentChild => currentChild.id === child.id)) {
			parents[parentIndex].children.push(child);
		}
		if (!cache.get(child.id)) cache.set(child.id, child);
	}
	parents.forEach(parent => cache.set(parent.id, parent));
	return parents;
}

// load roots of categories or components
export async function loadRoots({ type, doNotLoadChildren }) {
	if (rootsLoaded[type] && !doNotLoadChildren) return [];
	const filter = {
		$not: {
			field: '/data',
			comparison: 'exists',
			value: 'parent'
		}
	};
	const entityClass = await getEntityclassByExternalId(type);
	let roots = await search(entityClass.id, filter);
	if (!doNotLoadChildren) {
		saveToCache(roots);
		roots = await loadParentsWithChildren({ type, parentIds: roots.map(r => r.id) });
		if (roots) rootsLoaded[type] = true;
	}
	return roots;
}
