import { getEntityclassByExternalId } from '@/repository/system.js';
import { searchProducts, countProducts } from '@/repository/system';
import { getBackendSearchConfiguration } from '@/util/search';
import { EnableLogin } from '../util/env';

export const FALLBACK_LOCALE = 'en_GB';

const state = {
	lastSearch: null,
	searchResults: [],
	wasExecuted: false,
	searchCount: 0,
	activePageNum: 1,
	perPage: 10,
	entityClassId: null,
	sort: null,
	selectedLocale: FALLBACK_LOCALE,
	fallbackLocale: FALLBACK_LOCALE,
	mainCategoryFilter: [],
	hideCategorySelection: false,
	listFilter: [],
	standardFilter: [],
	showStandardFilter: true,
	euMode: false,
	preselectBioStandard: null,
	restrictStandards: null,
	restrictLanguages: [],
	usePaidUserUi: false,
	startItem: 0,
	pubSearchFields: [],
	listSpecificSettings: [],
	maintenanceMode: false,
	institutionsFilter: null
};

const SORT_FIELD_MAPPING = {
	'name': 'data.indexData.listId.name.lang',
	'distributor': 'data.indexData.listId.distributor.label'
};

function sortToArray(sorting, locale) {
	if (!sorting) {
		return [];
	}
	const field = SORT_FIELD_MAPPING[sorting.column];
	return [ `${field.replace('listId', sorting.listId).replace('lang', locale)}:${sorting.order}`];
}

function getDefaultConfig(config) {
	let defaultConfig = {};
	if (typeof config !== 'object') {
		const configElement = document.getElementById('search-configuration');
		if (configElement) {
			config = JSON.parse(configElement.innerHTML);
			if (config.readFromLocalStorage) {
				config = JSON.parse(localStorage.getItem('bml_pub_config') || '{}');
			}
		}
		if (!config) config = {};
	}
	defaultConfig.selectedLocale = config.defaultLanguage ? config.defaultLanguage : FALLBACK_LOCALE;
	defaultConfig.mainCategoryFilter = config.mainCategories ? config.mainCategories : [];
	defaultConfig.hideCategorySelection = config.hideCategorySelection ? config.hideCategorySelection : false;
	defaultConfig.listFilter = config.lists ? config.lists : [];
	defaultConfig.standardFilter = config.mandatoryStandards ? config.mandatoryStandards : [];
	defaultConfig.showStandardFilter = typeof config.showStandardFilter === 'boolean' ? config.showStandardFilter : true;
	defaultConfig.euMode = config.enableEuMode ? config.enableEuMode : false;
	defaultConfig.preselectBioStandard = config.preselectStandard ? config.preselectStandard : null;
	defaultConfig.restrictStandards = config.restrictStandards && Array.isArray(config.restrictStandards) && config.restrictStandards.length ? config.restrictStandards : null;
	defaultConfig.restrictLanguages = config.restrictLanguages ? config.restrictLanguages : [];
	defaultConfig.usePaidUserUi = typeof config.usePaidUserUi === 'boolean' ? config.usePaidUserUi : EnableLogin;
	defaultConfig.listSpecificSettings = config.listSpecificSettings || [];
	defaultConfig.institutionsFilter = Array.isArray(config.institutions) && config.institutions.length ? config.institutions : null;
	return defaultConfig;
}

const CLASS_ID = 'products';

const getters = {
	lastSearch: (state) => {
		return state.lastSearch;
	},
	searchResults: (state) => {
		return state.searchResults;
	},
	wasExecuted: (state) => {
		return state.wasExecuted;
	},
	isHistorySearch: (state) => {
		return state.lastSearch && state.lastSearch.searchHistory;
	},
	getActivePageNum: (state) => {
		return state.activePageNum;
	},
	getPerPage: (state) => {
		return state.perPage;
	},
	getSort: (state) => {
		return state.sort;
	},
	getLocale: (state) => {
		return state.selectedLocale;
	},
	getEuMode: (state) => {
		return state.euMode;
	},
	getFallbackLocale: (state) => {
		return state.fallbackLocale;
	},
	getRestrictStandards: (state) => (listId) => {
		let listSettings = state.listSpecificSettings.find(e => e.list === listId);
		if (listSettings && listSettings.restrictStandards) {
			return listSettings.restrictStandards;
		}
		return state.restrictStandards;
	},
	usePaidUserUi: (state) => {
		return state.usePaidUserUi;
	},
	getBackendParameters: (state, getters, rootState, rootGetters) => () => {
		let parameters = {
			standardFilter: state.standardFilter,
			locale: state.selectedLocale,
			fallbackLocale: state.fallbackLocale,
			isEuMode: state.euMode,
			mandatoryCategories: null,
			institutionsFilter: state.institutionsFilter
		};
		let categoryRoots = rootGetters['category/roots'];
		let selectedCategories = state.lastSearch && state.lastSearch.category ? JSON.parse(JSON.stringify(state.lastSearch.category)) : null;
		if ((!selectedCategories || !selectedCategories.length) && state.mainCategoryFilter) {
			parameters.mandatoryCategories = [];
			for (const category of state.mainCategoryFilter) {
				const mainCategory = categoryRoots.find(mainCategory => mainCategory.data.mainCategoryKey === category);
				if (mainCategory) {
					parameters.mandatoryCategories.push(mainCategory.id);
				}
			}
		}
		return parameters;
	},
	getDefaultConfig: () => (config) => {
		return getDefaultConfig(config);
	},
	getCategoryFilter: (state, getters, rootState, rootGetters) => (listId) => {
		let categoryRoot = rootGetters['category/roots'];
		const filterConfig = rootState.search.mainCategoryFilter;
		const currentList = rootGetters['list/byId'](listId);

		if (currentList && currentList.data.mainCategoriesAllowed) {
			categoryRoot = categoryRoot
				.filter(category => currentList.data.mainCategoriesAllowed.includes(category.id));
		}

		if (filterConfig && filterConfig.length) {
			categoryRoot = categoryRoot
				.filter(category => filterConfig.includes(category.data.mainCategoryKey));
		}

		return categoryRoot.map(category => category.id);
	},
	globalBackendParams: (state, getters) => {
		return {
			locale: getters.getLocale,
			fallbackLocale: getters.getFallbackLocale,
			isEuMode: getters.getEuMode
		};
	}
};

const actions = {

	async _loadEntityClass({ state, commit }) {
		if (state.entityClassId) {
			return;
		}
		const ec = await getEntityclassByExternalId(CLASS_ID);
		commit('setEntityClassId', { id: ec.id });
	},

	async search({ state, commit, getters }) {
		const sorting = sortToArray(state.sort, state.selectedLocale);
		const pagination = { start: state.startItem, limit: state.perPage };
		if (!state.lastSearch) return;
		const backendSearchConfiguration = getBackendSearchConfiguration(state.lastSearch, getters.getBackendParameters());
		const result = await searchProducts(backendSearchConfiguration, pagination, sorting);
		if (pagination && result.length < pagination.limit) {
			commit('setCount', { count: result.length + pagination.start });
		} else {
			let count = await countProducts(backendSearchConfiguration);
			commit('setCount', { count });
		}

		commit('setSearchResults', result);
	},

	searchForArchive({ getters }, { limit, searchConfiguration, countOnly }) {
		let searchFunction = countOnly ? countProducts : searchProducts;
		const pagination = {  limit };
		const backendSearchConfiguration = getBackendSearchConfiguration(searchConfiguration, getters.getBackendParameters());
		// console.debug('backend search', backendSearchConfiguration);
		return searchFunction(backendSearchConfiguration, pagination);
	},

	async loadSingleProductById({ getters, state }, { productId, historyDate }) {
		if (!historyDate) {
			let temp = state.searchResults.find(e => e.id === productId);
			if (temp) return JSON.parse(JSON.stringify(temp));
		}
		const product = await searchProducts(getBackendSearchConfiguration({ productId, searchHistoryDate: historyDate }, getters.getBackendParameters()));
		return product && product[0] ? product[0] : null;
	},

	setAdditionalListFilters({ commit, rootGetters }, listId) {
		const listData = rootGetters['list/byId'](listId);
		commit('setPubSearchFields', listData && listData.data.pubSearchFields ? listData.data.pubSearchFields : []);
	}

};

const mutations = {

	setEntityClassId(state, { id }) {
		state.entityClassId = id;
	},

	setDefaultConfig(state, config) {
		let newConfig = getDefaultConfig(config);
		for (let [ key, value ] of Object.entries(newConfig)) {
			state[key] = value;
		}
	},

	setLastSearch(state, search) {
		state.lastSearch = search;
	},

	resetSearch(state) {
		state.lastSearch = null;
		state.sort = null;
		state.wasExecuted = false;
		state.searchResults = [];
	},

	setSearchResults(state, searchResults) {
		state.searchResults = searchResults;
		state.wasExecuted = true;
	},

	setStartItem(state, startItem) {
		state.startItem = startItem;
	},

	setActivePageNum(state, page) {
		state.activePageNum = page;
	},

	setPerPage(state, page) {
		state.perPage = page;
	},

	setCount(state, { count }) {
		state.searchCount = count;
	},

	setSort(state, sort) {
		state.sort = sort;
	},

	setLocale(state, locale) {
		state.selectedLocale = locale;
	},

	setFallbackLocale(state, fallbackLocale) {
		state.fallbackLocale = fallbackLocale;
	},

	setPubSearchFields(state, pubSearchFields) {
		state.pubSearchFields = pubSearchFields;
	},

	setIsMaintenance(state) {
		state.maintenanceMode = true;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
