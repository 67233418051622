<template>
	<b-container class="bg-main">
		<b-card>
			<b-card-body v-if="!isLoggedIn">
				<p>
					{{ $t('menu.login.notLoggedIn') }}
				</p>
				<b-button
					@click="gotoLogin"
					variant="primary"
				>
					{{ $t('menu.login.login') }}
				</b-button>
			</b-card-body>
			<b-card-body v-else>
				<p>
					{{ $t('paiduser.invite.acceptLink') }}
				</p>
				<b-button @click="registerWithDomain()" variant="primary">
					{{ $t('paiduser.invite.acceptInvite') }}
				</b-button>
			</b-card-body>
		</b-card>
	</b-container>
</template>

<script>
import EventBus from '@/util/eventbus.js';

export default {
	name: 'AcceptInvite',

	computed: {
		isLoggedIn() {
			return this.$store.getters['auth/token/hasSsoLogin'];
		},
		inviteId() {
			return this.$route.query.id;
		},
		registrationName() {
			return this.$route.query.registrationName;
		}
	},

	created() {
		if (!this.inviteId || this.inviteId.trim() === '' || !this.registrationName || this.registrationName.trim() === '') {
			this.$router.replace('/');
		}
	},

	methods: {
		gotoLogin() {
			this.$store.dispatch('auth/token/authenticate');
		},
		async registerWithDomain() {
			await this.$store.dispatch('auth/user/registerWithInvite', {
				inviteId: this.inviteId,
				registrationName: this.registrationName.toLowerCase()
			});
			EventBus.$emit('success', this.$t('paiduser.messages.operationSuccessful'), this.$t('paiduser.messages.inviteAcceptSuccessful'));
			this.$router.replace('/');
		}
	}
};
</script>
