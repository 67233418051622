import Store from '@/store/index';

function _extendSelection(workingData, item, flattened) {
	let persistedItem = flattened.find(x => x.id === item);
	if (!persistedItem || !persistedItem.data.parent || persistedItem.data.parent === 'undefined') {
		// item is root, stop iteration
		return;
	}

	persistedItem = persistedItem.data;

	const parent = flattened.find(x => x.id === persistedItem.parent);
	if (parent) {
		workingData.push(persistedItem.parent);

		_extendSelection(workingData, parent, flattened);
	}
}

function _traverseSelectionTree(type, workingData) {
	// flatten hierarchical tree and traverse array upwards
	let flattenArray = function flattenArray(arr) {
		const flattened = [];
		arr.forEach(item => {
			flattened.push(item);

			if (item.children && Array.isArray(item.children)) {
				flattened.push(...flattenArray(item.children));
			}
		});

		return flattened;
	};

	let addChildren = function addChildren(arr, parent) {
		if (!parent.children) {
			return;
		}

		for (const child of parent.children) {
			arr.push(child);
			addChildren(arr, child);
		}
	};

	let ordered = [];
	for (const entry of Store.getters[`${type}/roots`]) {
		ordered.push(entry);
		addChildren(ordered, entry);
	}

	let flattened = flattenArray(ordered);

	for (const item of workingData) {
		_extendSelection(workingData, item, flattened);
	}

	let ret = [];
	for (const element of ordered) {
		if (workingData.find(x => x === element.id)) {
			ret.push(element.id);
		}
	}

	return ret;
}

export function getTotalSelection(type, workingData) {
	const selection = _traverseSelectionTree(type, workingData);
	return new Set(selection);
}
